.sidebar-divider {
    border-bottom: 1px solid #383e44 !important;
}

.sidebar-user-text p {
    text-transform: capitalize;
}

.sidebar-selected {
    background-color: #fff;
}

.sidebar-badge span {
    background-color: #e74c3c;
    color: #fff !important;
}

i.sidebar-icon {
    font-size: 16px;
    margin-left: 8px;
    color: #aaa;
}

.sidebar-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

span.sidebar-icon-container {
    margin-right: 8px;
}

li.sidebar-active,
li:hover.sidebar-active,
a.sidebar-active,
a:hover.sidebar-active {
    background-color: #ff0c9c;
    color:#fff !important;
}

li.sidebar-active h3,
li.sidebar-active i,
a.sidebar-active h3,
a.sidebar-active i,
a.sidebar-active span,
a.sidebar-active div p {
    color: #fff!important;
}

.sidebar-active > div,
.sidebar-nonactive > div,
div.sidebar-icon-root {
    margin-right: 0;
}

a.sidebar-nonactive {
    padding-right: 0;
}
