div.slim-input {
    /* border-radius: 3px;
    font-size: 15px; */
    font-family: "Gellix" !important;
}

div.slim-input > div > div {
    /* padding-top: 5px;
    padding-bottom: 5px; */
}
div.dark-datepicker.slim-month-input > div > input {
    font-family: "Gellix" !important;
    font-size: 15px;
    cursor: pointer;
    width: 80px;
}

.heading-div {
    background: #333333;
    padding: 12px;
    display: flex;
    flex-direction: row;
}

.heading-wrap {
    display: flex;
    background: #313030;
    padding: 12px;
    flex-direction: row;
}

.inside {
    white-space: nowrap;
}
.inside-right {
    flex: 1;
    text-align: right;
}

.heading-div span.widget-title-text,
.inside span.widget-title-text {
    color: #ffffff;
    font-size: 24px;
    font-family: "Gellix" !important;
    display: flex;
    flex-grow: 1;
    white-space: normal;
}

#heading-title {
    float: left;
    width: auto;
    white-space: pre-wrap;
}

#heading-action {
    float: left;
    clear: right;
    width: 100%;
}
#action-div {
    float: right;
    width: 100%;
}

@media (min-width: 768px) {
    .headingAction > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .headingContainer {
    }

    .headingTitle {
    }

    #heading-title {
        float: left;
        width: auto;
        white-space: nowrap;
    }

    #heading-action {
        float: left;
        clear: right;
        width: 100%;
    }
    #action-div {
        float: right;
        width: 100%;
    }
    #action-div > div {
        justify-content: flex-end;
    }

    .heading-wrap {
        display: flex;
        background: #313030;
        padding: 12px;
    }
    .inside {
        white-space: nowrap;
    }
    .inside-right {
        flex: 1;
        text-align: right;
    }
}

@media screen and (max-width: 1280px) {
    div.slim-input {
        border-radius: 3px;
        font-size: 15px;
    }

    div.slim-input > div > div {
        padding-top: 6px;
        padding-bottom: 5px;
    }

    div.slim-dark-input > div {
        padding-top: 3px;
        padding-bottom: 5px;
    }

    div.slim-dark-input > div.Select--single {
        padding-top: 5px;
        padding-bottom: 6px;
    }
    div.slim-dark-input > div.Select--single .Select-placeholder {
        font-family: "Gellix" !important;
    }

    div.slim-dark-input .Select-arrow-zone {
        top: -3px;
        padding-right: 2px;
    }

    /* div.slim-dark-input .Select-clear-zone {
        width: 21px;
        height: 21px;
        font-size: 21px;
    } */
    div.slim-dark-input .Select-clear-zone svg {
        font-size: 18px;
    }

    div.dark-datepicker.slim-input > div > input {
        padding-top: 6px;
        padding-bottom: 5px;
        font-family: "Gellix" !important;
        font-size: 15px;
        /* width: 81px; */
    }

    div.dark-datepicker.slim-month-input > div > input {
        padding-top: 6px;
        padding-bottom: 5px;
        font-family: "Gellix" !important;
        font-size: 15px;
        width: 120px;
    }

    div.slim-select-input {
        border-radius: 0;
    }

    div.slim-select-input > div > div {
        padding: 3px 26px 0px 8px;
        font-family: "Gellix" !important;
        font-size: 15px;
    }
}
