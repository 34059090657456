.table-toolbar {
    background-color: #ff0c9c !important;
    padding-right: 8px !important;
}

.table-toolbar-selected {
    background-color: #333333 !important;
    padding-right: 8px !important;
}

.actions {
    margin-right: 0;
    margin-left: auto;
}

.checkbox-column {
    padding-right: 8px !important;
    padding-left: 8px !important;
    width: 16px !important;
}

table.table-md {
}

table.table-md tr td,
table.table-md tr th {
    padding: 4px 12px 4px 12px;
}

table.table-md tr td:last-child,
table.table-md tr th:last-child {
    padding-right: 6px;
}

@media screen and (max-width: 1280px) {
    table.table-md tr td,
    table.table-md tr th {
        padding: 4px 8px 4px 8px;
    }
}
