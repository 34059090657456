div.lineup-card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    position: relative;
}

.lineup-card .card-avatar {
    height: 150px;
    position: relative;
}
.lineup-card .detail {
    margin-top: 18px;
    text-align: center;
}

.lineup-card .detail dl {
    overflow: auto;
    margin-bottom: 0;
}

.lineup-card .detail dl::after {
    content: "";
    clear: both;
    display: table;
}

.lineup-card .detail dt {
    float: left;
    clear: left;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);
}

.lineup-card .detail dd {
    float: right;
}
.lineup-card .detail dd.percentage {
    position: absolute;
    right: 15px;
}

.lineup-card .detail dd.percentage em {
    font-size: 80%;
    color: rgba(0, 0, 0, 0.54);
}

div.lineup-card .action {
    margin-top: -24px;
}

.lineup-card .avatar {
    position: absolute;
    bottom: -28px;
    float: right;
    right: calc(50% - 30px);
    border: 3px solid #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    width: 60px;
    height: 60px;
}
.lineup-card .action {
    text-align: right;
}

.lineup-card .list {
    margin-right: -18px;
    padding-top: 0;
}
.lineup-card .list ul {
    padding: 0;
}

.lineup-card .player-listitem {
    padding-left: 0;
    padding-right: 0;
}

.lineup-card .action .expander {
    transform: rotate(0deg);
    transition: transform 0.4s;
}

.lineup-card .action .expanderOpen {
    transform: rotate(180deg);
    transition: transform 0.4s;
}

.add-lineup-card{
    border: 3px dashed #ff0c9c;
    padding: 15px;
    height: 346px;
    width: 100%;
    min-height: 250px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    text-align: center;
    background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    );
    cursor: pointer
}

.add-lineup-card svg {
    font-size: 3em;
    color: rgba(255, 12, 156,  0.8);
}
