div.profile-header {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    padding: 10px 10px 50px 10px;
    background-color: #222222;
}

div.background-image-control {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
}

div.profile-header-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: 100;
    background-size: cover;
    background-position: center top;
}

div.profile-header-background-image.active {
    visibility: visible;
    opacity: 1;
    -webkit-transition: 2s ease-in-out; /* Safari */
    transition: 2s ease-in-out;
}

div.profile-header-background-image.inactive {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 2s ease-in-out; /* Safari */
    transition: 2s ease-in-out;
}

div.background-image-control.active {
    background-color: rgba(255, 12, 156,  0.9);
    border: 1px solid rgba(255, 12, 156,  1);
}

div.background-image-control.inactive {
    background-color: rgba(255, 12, 156,  0.6);
    border: 1px solid rgba(255, 12, 156,  0.7);
}

div.profile-body {
    background-color: #262626;
    display: flex;
}

div.profile-sidebar {
    background-color: #262626;
    width: 480px;
    min-width: 480px;
    padding: 30px;
    display: table-cell;
}

div.profile-content {
    background-color: #3A3B3D;
    padding: 16px;
    width: calc(100% - 480px);
    display: flex;
    align-items: flex-start;
}

@media (max-width: 960px) {
    div.profile-content {
        padding: 16px;
        width: 100%;
    }
}

.profile-header-inner {
    margin-top: 12px;
}

.profile-header-inner-slider {
    margin-top: 18px;
    margin-bottom: -28px;
    display: flex;
    justify-content: center;
}

.profile-header-information-container {
    z-index: 200;
    margin-bottom: 60px;
}

.profile-header-temp::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    bottom: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC44Ii8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g");
    background-size: 100%;
    background-image: -webkit-gradient(
        linear,
        50% 0%,
        50% 100%,
        color-stop(0%, rgba(0, 0, 0, 0)),
        color-stop(100%, rgba(0, 0, 0, 0.8))
    );
    background-image: -moz-linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
    );
    background-image: -webkit-linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
    );
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

div.profile-avatar {
    width: 120px;
    height: 120px;
    margin: 0px auto;
}

.profile-header-text p {
    color: #fff;
    text-shadow: 0px 0px 5px #000000;
}

.profile-header-text h3 {
    font-size: 30px;
    color: #fff;
    text-shadow: 0px 0px 5px #000000;
}

h4.profile-sidebar-title {
    font-size: 19px;
    color: rgba(255, 255, 255, 0.8);
}

.profile-content h4.profile-sidebar-title {
    font-size: 19px;
    color: #333;
}

svg.profile-header-icon {
    width: 16px;
    height: 16px;
    top: 3px;
    position: relative;
    filter: drop-shadow(0px 0px 1px #000);
}

button.button-profile-header {
    background-color: #ff0c9c;
    color: #b06f09;
    font-size: 0.875em;
    padding: 10px 20px;
}

button.button-profile-header:hover {
    opacity: 0.9;
    color: #333;
    background-color: #ff0c9c;
}

button.profile-sidebar-button {
    background-color: #404040;
    color: rgba(255, 255, 255, 0.8);
    width: 52px;
    height: 36px;
    min-width: 52px;
    min-height: 36px;
    padding: 0px;
}

button.profile-sidebar-button:hover,
button.profile-sidebar-button.active {
    background-color: #ff0c9c;
    color: #7f5006;
    width: 52px;
    height: 36px;
    min-width: 52px;
    min-height: 36px;
    padding: 0px;
}

span.profile-sidebar-icon-button {
    font-size: 12px;
}

.profile-sidebar .border-bottom-grid,
.profile-content .border-bottom-grid {
    border-bottom: 1px solid #404040;
    padding-bottom: 10px;
}

.profile-content .standalone-widget-title .widget-title {
    margin-bottom: 12px;
    margin-top: 0px;
}

.widget-title-bar-yellow.standalone-widget-title .widget-title {
    background-color: #ff0c9c;
}

.widget-title-bar-yellow.standalone-widget-title .widget-title span {
    color: #333;
    font-family: "GellixSemiBold";
}

.bio-section {
    color: #fff !important;
}

.bio-section h1,
.bio-section h2{
    color: #ff0c9c;
}

.bio-section a:link {
    color: #fff;
}

.bio-section a:visited {
    color: #ff0c9c;
}

.bio-section a:hover {
    color: #ff0c9c;
}

.bio-section a:active {
    color: #ff0c9c;
}

.bio-section p:last-child {
    margin-bottom: 0px;
}

.bio-section ol,
.bio-section ul{
    list-style: none;
}


.bio-section ul li::before,
.bio-section ol li::before{
    color: #ff0c9c;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
    font-size: 0.75rem;
}

.bio-section ul li::before{
    content: "\2022";
}

.bio-section ol li::before{
    content: counter(li)".";
}


.bio-section ol li {
    counter-increment: li;
}


div.profile-sidebar-contact div {
    display: flex;
    align-items: center;
    color: #ff0c9c;
}

div.profile-sidebar-trading-hours div {
    color: #798692;
}

div.profile-sidebar-contact svg {
    margin-right: 5px;
    font-size: 1rem;
}

p.perform-list-calendar-month-big {
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
}

p.perform-list-calendar-date-big {
    font-size: 60px;
    text-transform: uppercase;
    margin: -10px 0px;
    color: #333;
}

p.perform-list-calendar-day-big {
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
}

div.perform-list-calendar-container-big {
    background-color: #ff0c9c;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
}

p.perform-list-title-subheading {
    font-size: 1.1rem;
    margin-left: -3px;
}

.perform-list-content-big a,
.perform-list-content-big p,
.perform-list-content-big span {
    color: #798692;
    display: inline-block;
}

.perform-list-content-big .fa {
    font-size: 16px;
}

.perform-list-content-big svg {
    color: #798692;
}

.perform-list-content-big svg:hover,
.perform-list-content-big i:hover {
    color: #aab;
    cursor: pointer;
}

div.perform-list-content-big {
    padding: 16px !important;
    background-color: #333;
    display: flex;
}

p.error-404-big {
    font-size: 13rem;
    margin-bottom: -20px;
}

p.error-404 {
    margin-bottom: -10px;
}

div.error-404-container {
    padding: 16px;
    height: 100vh;
    width: 100%;
    align-content: center;
    align-items: center;

    background: url(../../img/background.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

div.error-404-container p {
    color: #333;
    font-family: "GellixThin";
    text-shadow: 2px 2px 6px rgba(255, 12, 156,  0.6);
}

div.venue-profile-gig-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

div .profile-header-inner .actionButton{
    margin-left: 4px !important;
    margin-right: 4px !important;
}