.hgBillingTable {
    border-collapse: collapse;
    width: 100%;
    border-radius: 20px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 0.5px #666; /* this draws the table border  */ 
    overflow: hidden
  }


  .hgBillingChild td, .hgBillingChild th {
    padding: 5px !important;
  }

  .hgBillingTable td, .hgBillingTable th {
    padding: 10px;
  }
  .hgBillingTable td {
    font-size:11px
  }

  .hgBillingTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }

.billingHgCell{
    padding: 7px;
    /* height:100% */
}

.billingHg{
    padding-left: 5vh;
    padding-right: 10vh;
}

.billingBullet {
    margin: 1vh;
    padding: 1vh;
   
}

.hgBillingAlignTop {
    vertical-align: top;
}

.hgTrTable {
    height: 320px;
}


.hgBillingFooter {
    border: 1px solid #ddd;
    height: 100px;
}

.hgBillingBorderRight {
    border-right: 0.5px #666 solid;
}

.hgBillingChild {
    table-layout: fixed;
    text-align: center;
}

.tablePayment td, tr {
  vertical-align: middle;
  margin-left:10px;
}

.tablePayment td {
  height: 100px;
}

.cardPayment {
  position: relative;
  /* ... other styles ... */
}

.cardPaymentText {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  background-color: #ff0c9c;
  font-size: 9px;
  color: white;
  padding: 5px 5px;
  border-radius: 5px;
}