div.perform-list {
    padding: 8px;
}

p.perform-list-calendar-month {
    font-size: 10px;
    text-transform: uppercase;
    color: #333;
}

p.perform-list-calendar-date {
    font-size: 48px;
    text-transform: uppercase;
    margin: -10px 0px;
    color: #333;
}

p.perform-list-calendar-day {
    font-size: 12px;
    text-transform: uppercase;
    color: #333;
}

span.perform-list-icon {
    font-size: 14px;
}

div.perform-list-icon-container {
    padding: 6px !important;
}

.perform-list-content {
    background-color: #333;
    padding: 16px;
    min-height: 120px;
}

.perform-list-content a,
.perform-list-content p,
.perform-list-content span {
    display: inline-block;
    color: #798692;
    font-size: 0.875rem;
}

.perform-list-content a:hover,
.perform-list-content a:active,
.perform-list-content a:visited {
    color: #798692;
    text-decoration: none;
}

.perform-list-content .fa {
    font-size: 12px;
    color: #798692;
    width: 20px;
    text-align: center;
}

div.perform-list-calendar-info {
    background-color: #ff0c9c;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

div.perform-item-overlay.clickable {
    background: -moz-linear-gradient(
        top,
        rgba(38, 38, 38, 0.8) 0%,
        rgba(38, 38, 38, 0.99) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(38, 38, 38, 0.8) 0%,
        rgba(38, 38, 38, 0.99) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(38, 38, 38, 0.8) 0%,
        rgba(38, 38, 38, 0.99) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    z-index: 100;
    width: 428px;
    height: 144px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

@media (max-width: 960px) {
    div.perform-item-overlay.clickable {
        padding: 0px;
        width: 100%;
        left: 0px;
    }
}

div.perform-item-overlay.clickable:hover {
    background: -moz-linear-gradient(
        top,
        rgba(38, 38, 38, 0.85) 0%,
        rgba(38, 38, 38, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(38, 38, 38, 0.85) 0%,
        rgba(38, 38, 38, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(38, 38, 38, 0.85) 0%,
        rgba(38, 38, 38, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

div.perform-item-overlay.clickable svg {
    font-size: 6em;
    color: rgba(255, 12, 156,  0.8);
    margin-bottom: -30px;
    margin-top: -18px;
}

div.perform-item-overlay.clickable:hover svg {
    color: rgba(255, 12, 156,  1);
}

div.perform-item-overlay.clickable p {
    color: rgba(255, 12, 156,  0.9);
}

div.perform-item-overlay.clickable:hover p {
    color: rgba(255, 12, 156,  1);
}

div.perform-performlist-container {
    max-height: 432px;
    overflow: hidden;
}

div.perform-performlist-container.expanded {
    max-height: 432px;
    overflow: auto;
}

div.soundcould-widget-venue-profile iframe {
    height: 100%;
}

div.soundcould-widget iframe {
    height: 100% !important;
}
