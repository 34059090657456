.pdf-viewer-container-md,
.pdf-viewer-container-lg,
.pdf-viewer-container-xl {
    min-height: 580px;
    width: 100%;
    position: relative;
}

.pdf-viewer-container-xs,
.pdf-viewer-container-sm {
    min-height: 620px;
    width: 100%;
    position: relative;
}

.pdf-container-md,
.pdf-container-lg,
.pdf-container-xl {
    border: none;
    min-height: 580px;
    width: 100%;
    /* height: 100%; */
    position: relative;
    background-color: #555555;
    padding-top: 40px;
}

.pdf-container-xs,
.pdf-container-sm {
    border: none;
    min-height: 620px;
    width: 100%;
    max-height: 640px;
    position: relative;
    background-color: #555555;
    padding-top: 80px;
}

.pdf-document-container {
    border: none;
    width: 100%;
    overflow-x: auto;
    overflow-y: scroll;
    position: relative;
}

.pdf-load-indicator {
    position: fixed;
    left: 50%;
    top: 50%;
    display: block;
}

.pdf-document-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555555;
}

.pdf-document-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #555555;
}

.pdf-document-container::-webkit-scrollbar-thumb {
    background-color: #333333;
    border: 2px solid #555555;
}

.pdf-document-container canvas {
    margin-left: auto;
    margin-right: auto;
}

.pdf-document-container annotationLayer {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 894px !important;
    height: 1263px !important;
    color: transparent !important;
    transform: translate(-50%, -50%) rotate(0deg) !important;
    pointer-events: none !important;
}

.pdf-action-container {
    position: absolute;
    bottom: 8px;
    right: 20px;
    width: 240px;
}

.pdf-action-container-small {
    position: absolute;
    bottom: 8px;
    left: 50%;
    margin-left: -120px;
    width: 240px;
}

.pdf-zoom-button {
    width: 32px !important;
    height: 32px !important;
}

.pdf-pagination-action {
    position: absolute;
    right: 20px;
    top: 8px;
}

.pdf-viewer-header-md,
.pdf-viewer-header-lg,
.pdf-viewer-header-xl {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #333333;
    height: 36px;
    z-index: 2;
    padding-left: 16px;
    padding-right: 16px;
}

.pdf-viewer-header-xs,
.pdf-viewer-header-sm {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #333333;
    height: 72px;
    z-index: 2;
    padding-left: 16px;
    padding-right: 16px;
}

.pdf-title-container {
    margin-top: 8px !important;
    color: #fff !important;
    font-size: 16px !important;
}

.pdf-toolbar-page-nav {
    width: 100%;
    text-align: center;
}

.pdf-page-textfield {
    width: 100px !important;
    height: 28px;
    margin-top: 4px !important;
}

.pdf-page-textfield input {
    padding-top: 12px !important;
    width: 40px !important;
    text-align: right;
    border-radius: 0 !important;
}

.pdf-page-totalPages {
    width: 60px !important;
    border-radius: 0 !important;
}

.pdf-toolbar-action-container {
    width: 100%;
    text-align: right;
    margin-top: 4px;
}

.pdf-toolbar-action-btn {
    width: 32px !important;
    height: 32px !important;
    color: #fff !important;
    margin-left: 4px !important;
}

.pdf-toolbar-action-btn:disabled {
    width: 32px !important;
    height: 32px !important;
    color: #777 !important;
    margin-left: 4px !important;
}
