button.button-social {
    background-color: #333333;
    color: #ffffff;
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    padding: 0px;
    margin: 2px;
}

button.button-webSite:hover {
    background-color: #34495e;
}

button.button-facebook:hover {
    background-color: #3b5998;
}

button.button-twitter:hover {
    background-color: #00aced;
}

button.button-googlePlus:hover {
    background-color: #dd4b39;
}

button.button-linkedIn:hover {
    background-color: #007bb5;
}

button.button-soundcloud:hover {
    background-color: #ff3a00;
}

button.button-bandcamp:hover {
    background-color: #477987;
}

button.button-mixcloud:hover {
    background-color: #34495e;
}

button.button-youtube:hover {
    background-color: #cc181e;
}

button.button-iTunes:hover {
    background-color: #aaa;
}

button.button-spotify:hover {
    background-color: #1db954;
}

button.button-email:hover {
    background-color: #f09433;
}

button.button-whatsapp:hover {
    background-color: #25d366;
}

button.button-instagram:hover {
    background: #f09433;
    background: -moz-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: -webkit-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

span.icon-button-social {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.button-social-link:link,
a.button-social-link:hover,
a.button-social-link:visited,
a.button-social-link:active {
    text-decoration: none;
}
