.new-setlist-container {
    margin: 24px;
}
*/ #btnAddNewSetlist {
    line-height: 13px;
}

@media (min-width: 768px) {
    #selectSong {
        padding-top: 37px;
    }
}
