.clickLink:hover {
    cursor: pointer;
  }
.imageItemDetail{
    flex-shrink: 0;
    width: 400px;
    height: 400px;
    max-width: 400px;
    max-height: 400px;
    border-radius: 20px !important;
    background-color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
}

.imageStyleDetail {
    min-width: 400px;
    min-height: 400px;
    object-fit: cover;
    max-width: 400px;
    max-height: 400px;
    z-index: 1;
}

.imageStyleDetailInfo {
    min-height: 400px;
    object-fit: cover;
    max-height: 400px;
    z-index: 1;
}

.gridInfo{
    display: 'inline-flex';
    padding: 30px;
    height: 100%;
    min-width: 400px;
}

.genreContainer{
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;
    max-width: 400px;
}
.genreWrapper{
    height: 25px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin: 3px;
    display: flex;
    flex-wrap: wrap;
    border-width: 1px;
    padding: 5px;
    font-size: 12px;
    font-family:"Open Sans";
}
.buttonStyle{
    border-radius: 20px !important;
    border-width: 1px !important;
    height: 40px;
    width:110px;
    font-size: 12px !important;
    font-family:"Open Sans" !important;
}

.buttonStyleShare{
    border-radius: 20px !important;
    background-color: #58ad7a !important;
    border-width: 1px !important;
    height: 40px;
    width:110px;
    color: white !important;
    font-size: 12px !important;
    font-family:"Open Sans" !important;
}

