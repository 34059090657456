.Confirmation {
    display: block;
    padding: 20px;
    min-height: 100vh;
}

.Confirmation::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: url(../img/hero3.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -webkit-filter: blur(5px) brightness(35%);
    -moz-filter: blur(5px) brightness(35%);
    -o-filter: blur(5px) brightness(35%);
    -ms-filter: blur(5px) brightness(35%);
    filter: blur(5px) brightness(35%);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}

.Confirmation #h-confirmation {
    margin-bottom: 0;
    font-size: 2.5em;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--main-white);
}

.Confirmation .sub-heading {
    font-size: 1.5em;
    letter-spacing: 2px;
    color: var(--shade);
}

.Confirmation .main-grid {
    margin: 300px 0;
}

.Confirmation .intro {
    padding: 25px;
    text-align: center;
    box-shadow: none;
}

.Confirmation .resend {
    padding: 35px;
    text-align: center;
    box-shadow: none;
}

.Confirmation .resend-link {
    font-size: 0.7em;
    letter-spacing: 2px;
    color: var(--main-white);
    display: inline !important;
}

.Confirmation .resend-link .link {
    color: #ff0c9c;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}

.Confirmation .btnSubmit {
    margin-top: 20px;
    color: var(--main-white) !important;
    color: rgb(255, 255, 255);
    width: 170px;
    height: 60px;
}

.Confirmation.btnSubmit:disabled {
    background: #b5b5b5;
}

.Confirmation .wait-msg {
    color: rgb(255, 255, 255);
    margin-top: 30px;
    opacity: 0.8;
}
