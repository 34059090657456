@import "./var.css";

html,
body {
    margin: 0;
    padding: 0;
    font-family: "Gellix","OpenSans", sans-serif;
    overflow-x: hidden;
    max-width: 100vw;
    font-size: 16px;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

*::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    background-color: #262626;
    border: 1px solid #262626;
}

.noScrollbar::-webkit-scrollbar-track {
    display: none;
}

.noScrollbar::-webkit-scrollbar {
    display: none;
}

.noScrollbar::-webkit-scrollbar-thumb {
    display: none;
}

legend {
    border-bottom: none;
}
