.booking-title {
    margin: 0px 0 20px;
}
.cal-header {
    padding: 1em;
    background: #333333;
}

.cal-header .calendar-title {
    margin: 0;
    color: #ffffff;
    margin-top: 5px;
}
.cal-header:before,
.cal-header:after {
    content: " ";
    display: table;
}
.cal-header:after {
    clear: both;
}

.cal-header .calendar-filter {
    min-width: 200px;
}

.cal-header .calendar-filter {
    background: #404040;
    color: #ff0c9c;
}
.cal-header .calendar-filter .calendar-select {
    background: #404040;
    color: #ff0c9c;
    padding: 2px 10px;
}

.cal-header .calendar-filter .calendar-select svg {
    color: #ff0c9c;
}

.cal-header .calendar-filter div.calendar-select:before {
    background-color: transparent;
}

.booking-calendar {
    min-height: 600px;
}

.booking-calendar .rbc-header {
    padding: 10px 3px;
    background: #f8c573;
}
.booking-calendar .rbc-month-view {
    background-color: #fff;
}

.booking-calendar .rbc-month-view .rbc-header + .rbc-header {
    border-left: 1px solid #ff0c9c;
}

.booking-calendar .rbc-toolbar {
    padding: 10px;
    background-color: #ff0c9c;
}
.booking-calendar .rbc-toolbar .rbc-toolbar-label {
    font-weight: bold;
}

.booking-calendar .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #f2f4f8;
}

.booking-calendar {
    height: fit-content !important;
}

.rbc-row-content {
    height: max-content !important;
}

/* .gridBookingForm {
    padding: 13px !important;
} */

.booking-calendar .rbc-event {
    background-color: #ff0c9c;
    border-radius: 5px;
    padding: 0px;
}

.booking-calendar a.rbc-show-more,
.booking-calendar a.rbc-show-more:hover,
.booking-calendar a.rbc-show-more:active,
.booking-calendar a.rbc-show-more:visited {
    color: #ff0c9c;
    text-decoration: none;
}

.booking-calendar .rbc-day-slot .rbc-event {
    border: 1px solid #cc830e;
}

.booking-calendar .rbc-today {
    background-color: #f29c1233;
}

.booking-calendar .rbc-selected-cell {
    background-color: #f29c1222;
}

.booking-calendar .rbc-off-range-bg {
    background-color: rgba(138, 89, 11, 0.199);
}

.booking-calendar .rbc-event.rbc-selected {
    background-color: #333;
}

button.extra-gig-fee-inline-button {
    /* height: 100%; */
    height: 41px;
    color: #000;
    background-color: #ff0c9c;
    border-radius: 0 3px 3px 0;
}

button.extra-gig-fee-inline-button:hover {
    background-color: #ff0c9caa;
}

button.extra-gig-fee-inline-button-disabled {
    height: 41px;
    /* height: 100%; */
    color: #000;
    background-color: #ccc;
    border-radius: 0 3px 3px 0;
}

div.booking-paper-form {
    border-radius: 4px;
    box-shadow: none;
}

.rbc-month-row {
    height: max-content !important;
}

.booking-date-picker > div {
    height: 41px;
}
.booking-date-picker button {
    height: 40px;
    width: 40px;
}