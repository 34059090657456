.widget h1,
.widget h2,
.widget h3,
.widget p,
.widget span {
    font-family: "GellixThin" !important;
    text-transform: none;
}

div.dashboard-widget-summary-card-container {
    height: 177px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
div.dashboard-widget-summary-card-container .caption {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
}

div.dashboard-widget-summary-card-container.widget h1 {
    font-size: 42px;
    line-height: 42px;
}

div.dashboard-widget-summary-card-container.widget h3 {
    font-size: 24px;
    line-height: 24px;
}

.card-header-primary {
    height: 88px;
    background-color: #ff0c9c;
    width: 100%;
    color: #000;
}

.card-header-secondary {
    height: 88px;
    background-color: #333333 !important;
    width: 100%;
    color: #fff;
}

.card-header-secondary h1,
.card-header-secondary h2,
.card-header-secondary h3 {
    color: #fff !important;
}

.card-summary-content {
    height: 89px;
    background-color: #fff;
    width: 100%;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
