.calendar-custom-toolbar {
    padding: 8px;
    background-color: #313030;
}

.custom-toolbar-today-button-container {
    text-align: left !important;
}

.custom-toolbar-today-button-container button {
    color: white !important;
}

.custom-toolbar-today-button {
    margin-left: auto !important;
    margin-right: auto !important;
    background-color: #ff0c9c !important;
}

div.yellow-select {
    background-color: #ff0c9c !important;
    color: white !important;
}

div.yellow-select svg{
    color: white !important;
}
