.itembadge {
    right: -50px !important;
    margin-top: 8px;
}

.viewInvoiceButton {
    margin-right: 10px !important;
    margin-top: 5px !important;
    padding: 6px !important;
    font-size: 11px !important;
}

.viewInvoiceForm {
    margin-right: 10px !important;
    margin-top: 5px !important;
    padding: 6px !important;
    font-size: 13px !important;
}

  .multi-input-textbox::placeholder {  
    font-size: 10px;
    color: #aaa;
  }
  .multi-input-textbox { 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex-grow: 1;
    font-size: 16px;
  }
  .multi-input-textbox.error-input {
      border-color: red;
  }