@import "../../../var.css";

.private-booking-type {
    margin: 15px;
    text-align: center;
}

.private-booking-type button {
    width: 100%;
    overflow: hidden;
}

.private-booking-type button [type="subheading"] {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.private-booking-type button [type="subheading"] .type-image {
    width: 100px;
    height: 100px;
    border: solid 3px var(--main-black);
    border-radius: 100%;
    margin-bottom: 30px;
    padding: 25px;
}

.private-booking-type button:hover [type="subheading"] .type-image {
    background: var(--main-white);
    filter: unset;
}

.private-booking-type button [type="subheading"] img {
    width: 100%;
    /* height: 100%; */
    object-fit: contain;
}

.private-booking-type button:hover [type="subheading"] img {
    -webkit-filter: brightness(0);
    -moz-filter: brightness(0);
    -o-filter: brightness(0);
    filter: brightness(0);
}

.private-booking-type button [type="subheading"] .title {
    color: var(--main-black);
    padding: 5px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.private-booking-type.goToDashboard [type="subheading"] .title {
    font-size: 1.5em;
    padding: 15px;
    padding-bottom: 0;
}

.private-booking-type button [type="subheading"] .subtitle {
    display: inline-block;
    text-transform: lowercase;
    color: var(--main-black);
    font-size: 1.1em;
    letter-spacing: 1px;
    color: var(--main-white);
    padding-bottom: 10px;
}

.private-booking-type.goToDashboard [type="subheading"] .subtitle {
    font-size: 1.3em;
}

@media (min-width: 768px) {
    .private-booking-type button {
        width: 250px;
        overflow: hidden;
    }
}

@media (max-width: 960px) {
    .private-booking-type button [type="subheading"] .subtitle {
        color: var(--shade);
    }
}
