.displayNone {
    display: none;
}

.loadingMask {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #44444444;
    overflow: no-content;
}

.loadingSpinner {
    position: absolute;
    left: 50%;
    top: 50%;
}

.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 16777271;
    overflow: no-content;
}

.panelLoad {
    z-index: 1002;
    margin-left: -20px !important;
    margin-top: -35px !important;
    -webkit-animation: panel 2.2s infinite forwards !important;
    animation: panel 2.2s infinite forwards !important;
}

.panel-cube {
    margin: -20px 0 0 -20px !important;
    margin: 0 auto !important;
    position: absolute !important;
    left: 50% !important;
    margin-left: -63px !important;
    top: 50% !important;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
}

@media screen and (max-width: 768px) {
    .panel-cube {
        top: 50vw !important;
        left: 47vw !important;
    }
}

@media screen and (max-width: 568px) {
    .panel-cube {
        top: 50vw !important;
    }
}

.spin-container {
    width: 80px;
    height: 100px;
    position: relative;
    margin: 0px;
}

.spin-img {
    top: 0px;
    position: absolute;
    width: 80px;
    height: 100px;
}

#card {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 1s;
    transition: -webkit-transform 1s;
    animation: rotate 3s linear infinite;
}

.back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    position: absolute;
    top: 0px;
}

@keyframes rotate {
    from {
        -webkit-transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(360deg);
    }
}
