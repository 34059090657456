.admin-table tr th {
    padding: 4px;
}

.admin-table tr td {
    padding: 4px;
}

.admin-table tr td.nopad,
.admin-table tr th.nopad {
    padding: 0;
}

.admin-table tr td.action {
    text-align: right;
    padding-right: 20px;
}

.admin-table tr td label {
    margin: 0;
}

.import-table tr {
    height: 40px;
}

.import-table tr td {
    padding: 8px;
}
