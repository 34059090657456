.notif-container {
    width: 288px;
    height: 430px;
}

@media (min-width: 360px) {
    .notif-container {
        width: 327px;
        height: 452px;
    }
}

@media (min-width: 375px) {
    .notif-container {
        width: 343px;
        height: 452px;
    }
}

@media (min-width: 414px) {
    .notif-container {
        width: 382px;
        height: 452px;
    }
}

@media (min-width: 768px) {
    .notif-container {
        width: 402px;
        height: 452px;
    }
}

.notif-container .notif-header {
    background-color: #333;
    color: #fff;
    height: 48px;
}

.notif-container .notif-header .notif-title {
    float: left;
    padding: 14px 15px;
}

.notif-container #notif-setting {
    float: right;
    color: #fff;
}

.notif-container .unread li span {
    font-weight: bold;
    font-family: "GellixSemiBold";
}

.notif-container a:hover {
    text-decoration: none;
}

button.btn-notification {
    padding: 2px 5px 0px;
    min-height: 28px;
}
