.facebook-message-post textarea {
    height: 200px !important;
}

.facebook-message-post-title {
    background-color: #ff0c9c !important;
    color: white !important
}

.facebook-message-post-title h2 {
    color: white !important;
}

.facebook-back-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
