

table {
    border-collapse: collapse;
    width: 100%;
    page-break-inside: auto;
}

tr {
    page-break-inside: avoid;
    page-break-after: auto;
}

.header-left-col {
    font-size: 16px;
}

.header-right-col {
    width: 50%;
    text-align: right;
    align-content: flex-end;
    align-items: flex-end;
    font-size: 16px;
}

.tax-invoice {
    font-size: 40px;
    vertical-align: center;
}

.payment-to {
    font-family: LatoWeb, sans-serif;
    font-size: 14px;
    font-weight: 100;
}

.logo-container {
    width: 76px;
}

.avatar {
    border: 0px solid #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-size: cover;
    background-position: center center;
    object-fit: cover;
}