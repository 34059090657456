
.bodyStyle {
    background-color: transparent !important;
}
.header-embed {
    z-index: 1;
    position: fixed !important;
    background-color: white;
    top: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100% !important;
}

.header-embed h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70% !important;
}

.gig-table-container-grid {
    margin-top: 30px !important;
}

.space-top-10 {
    padding-top: 20px;
}

.space-top-12 {
    padding-top: 12px !important;
}


.gridLayout {
    border: 'solid';
    width:100%;
    display: 'inline-flex';
    padding: 10px;

}

.gridItem {
    text-align: 'center';
    border: 'solid';
    display: 'inline-flex';
    border-radius: 50px;
    width: 100%;
    aspect-ratio: 1/1;
}
.imageItem{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    border-radius: 20px !important;
}

.imageStyle {
    width:100%;
    aspect-ratio: 1/1 !important;
    object-fit: cover;
    z-index: 1;
}

.topcorner{
    position:absolute;
    top:10px;
    right: 10px;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
 }

 .bottomText{
    position: absolute;
    bottom: 15px;
    left: 5%;
    width: 80%;
    flex-wrap: wrap;
    color: white;
    z-index: 3;
 }

 .embed-footer-grid {
    position: fixed;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 50%;
    position: relative;
    height: 100%;
    color: white;
    text-align: center;
    align-items: center;
}
 .bottomRightCorner{
    position: absolute;
    bottom: 20px;
    right: 20px;
    width:20px;
    height:20px;
    flex-wrap: wrap;
    color: white;
    z-index: 3;
 }

 .bottomSection{
    position: absolute;
    bottom: 0px;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    width: 100%;
    z-index: 2;
 }

 .bottomSectionMini{
    position: absolute;
    bottom: 0px;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    width: 100%;
    z-index: 2;
 }

 .imageFooter:hover {
    cursor: pointer;
  }