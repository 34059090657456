.gig-image .dropzone{
    cursor: pointer;
    /* background: url(../../img/blank-profile.png) no-repeat;
    background-size: cover; */
    margin-bottom: 40px;
}

.gig-image .dropzone p {
    margin: 0;
    text-align: center;
    width: 100%;
}

.gig-image  .avatar {
    width: 200px;
    height: 200px;
    margin: auto;
}