.Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: '100%';
    width: 100%;
    overflow: hidden;
}

.Footer .image-container {
    display: flex;
    flex: 1;
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 500px;
    z-index: -1000;
    background: rgba(0,0,0,0.3);
    overflow: hidden;
}

.Footer .image-container .image-bg {
    display: flex;
    opacity: 0.3;
    width:100%;
    height: auto;
    resize: cover;
    width: 100vw;
    height: 100%;
    /* z-index: -40; */
    background: url('../../img/footer.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
    /* background: url('../../img/footer.png'); */
}


.image-bg {
    display: flex;
    flex: 1;
    z-index: -2;
    position: absolute;
    width: 100vw;
    height: 50vh;
    opacity: 0.4;
    background-color: rgb(29, 29, 29);
    background-size: cover;
}

.Footer .container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 50px;
    max-width: 80vw;
}

.Footer .container p {
    color: white;
}

.Footer .container h1 {
    font-weight: bold;
    font-size: 50px;
    color: #ff0c9c;
}

.Footer .container .container-content {
    margin-right: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
}

.Footer .container .container-content .social-container {
    margin-top: 20px;
    display: flex;
    flex: 1;
    align-self: center;
}

.Footer .container .container-content-with-field {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-end;
}

.arr-text {
    display: flex;
    flex: 1;
    align-items: 1;
    justify-content: center;
}

@media screen and (max-width: 600px) {

    .Footer .bg {
        width: '100%';
    }

    .Footer .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }

    .Footer .container .container-content {
        padding-top: 20px;
        padding-bottom: 40px;
    }
}