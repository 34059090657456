div.sticky-information {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
}

.musician-grid-item {
    margin-right: 10px;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.List {
    width: 100%;
}

.small-roster-card {
    position: relative;
    display: flex !important;
}

.small-roster-container {
    display: flex !important;
    flex-direction: column !important;
}

.small-roster-content {
    flex: 1 0 auto !important;
}

.small-roster-image {
    width: 120px;
    height: 120px;
}

.small-roster-action {
    display: flex;
    align-items: center;
}

.roster-wrapper {
    overflow: visible !important;
    /* margin: 8px 0; */
}

.instrument-input {
    min-height: 41px;
    /* max-height: 41px; */
    height: 100%;
}

.instrument-input .Select--multi {
    padding: 0px !important;
}

.instrument-input .Select--multi .Select-control {
    height: 100%;
    padding-right: 8px;
}

.instrument-input .Select--multi .Select-placeholder {
    left: 8px;
    right: 8px;
}

.drag-wrapper {
    margin-bottom: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
