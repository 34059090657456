.action-list {
    width: "100%";
    max-width: 360;
    background-color: #262626;
}

header .toolbar-gutter {
    padding: 0;
    max-width: 100vw;
}

.toolbar-gutter .btnSignIn {
    color: #ff0c9c;
}

.profile-selector-container {
    width: 320px;
}

.main-profile-container {
    padding: 20px;
    /* background-color: #262626; */
}

div.profile-selector-avatar-main {
    width: 96px;
    height: 96px;
}

.profile-selector-container div.bold {
    font-size: 1.1em;
    /* color: #CCC; */
}

.profile-selector-container div.capitalize {
    font-family: "GellixThin";
    text-transform: capitalize;
    /* color: #CCC; */
}

ul.profile-selector-list {
    background-color: #f3f3f3;
}

ul.profile-selector-list p {
    text-transform: capitalize;
}

.profile-selector-footer {
    /* background-color: #262626; */
    padding: 10px;
}

.profile-selector-container a:hover,
.profile-selector-container a:focus {
    color: #262626;
    outline: 0px;
}

a.button-profile-selector-main p {
    font-size: 0.75rem;
}

a.button-profile-selector-main {
    padding: 0px 6px;
    min-height: 28px;
}

a.button-profile-selector-main svg {
    font-size: 0.9rem;
}

div.profile-selector-link,
.profile-selector-link a {
    font-size: 0.825rem;
}

.profile-selector-link a {
    color: #ff0c9c;
}

.profile-selector-link a:hover {
    color: #b20069;
}

div.profile-selector-profile-type {
    font-size: 0.8rem;
}

#avatar-btn {
    padding: 8px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
}

.icon-center {
    text-align: center;
}

@media (max-width: 767px) {
    #avatar-btn {
        margin-right: 8px;
        margin-left: 5px;
    }
}

a.header-button-padding {
    margin: 0px 16px;
}

div.add-profile-dialog-content {
    padding: 16px 16px 24px;
}

div.add-profile-dialog-content a,
div.add-profile-dialog-content a:hover,
div.add-profile-dialog-content a:visited,
div.add-profile-dialog-content a:focus {
    color: #333;
    text-decoration: none;
}

div.transparent-select {
    background-color: transparent;
}
div.transparent-select > div > div {
    padding: 5px 10px 4px 10px;
}
div.transparent-select > div > svg {
    padding-left: 10px;
}
div.dark-select {
    background-color: #404040;
    color: #A9A9A9;
}

div.dark-select > div > div {
    background-color: #404040;
    color: #A9A9A9;
}

div.dark-select > div > svg {
    color: #A9A9A9 !important;
}

div.transparent-input {
    background-color: transparent;
}

div.transparent-datepicker {
    background-color: transparent;
}

div.dark-datepicker > div {
    background-color: #404040;
    color: #ff0c9c;
    border-radius: 0;
}

.dark-datepicker input {
    color: #A9A9A9 !important;
}

.searchInput {
    max-width: calc(100vw - 32px);
}

.searchInput > div {
    background-color: transparent !important;
    box-shadow: none !important;
}

.add-profile-dialog-content .type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.add-profile-dialog-content .type button {
    width: unset;
    overflow: hidden;
}

.add-profile-dialog-content .type button [type="subheading"] .type-image {
    -webkit-filter: brightness(0);
    -moz-filter: brightness(0);
    -o-filter: brightness(0);
    filter: brightness(0);
    border: 0;
    padding: 0;
    margin-bottom: 16px;
    width: 50px;
    height: 50px;
}
