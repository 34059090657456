.rc-time-picker-input {
    background: transparent;
    border: 0;
    padding: 0 10px 0 10px;
    font-family: inherit;
    font-size: inherit;
    margin-top: 10px;
}

.rc-time-picker-panel {
    line-height: 48px;
    font-family: "OpenSans", sans-serif;
}

.rc-time-picker-panel-input {
    border: 0;
    padding: 0;
    background: transparent;
    font-size: 16px;
    line-height: 20px;
}

.rc-time-picker-panel-select {
    overflow-x: hidden;
}

.rc-time-picker {
    padding: 0 !important;
}
.timepicker-menu {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
