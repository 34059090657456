div.event-popup-size {
    width: 584px;
    height: 283px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.booking-title {
    margin: 0px 0 20px;
}
.cal-header {
    padding: 1em;
    background: #333333;
}

.cal-header .calendar-title {
    margin: 0;
    color: #ffffff;
    margin-top: 5px;
}
.cal-header:before,
.cal-header:after {
    content: " ";
    display: table;
}
.cal-header:after {
    clear: both;
}

.cal-header .calendar-filter {
    min-width: 200px;
}

.cal-header .calendar-filter {
    background: #404040;
    color: #ff0c9c;
}
.cal-header .calendar-filter .calendar-select {
    background: #404040;
    color: #ff0c9c;
    padding: 2px 10px;
}

.cal-header .calendar-filter .calendar-select svg {
    color: #ff0c9c;
}

.cal-header .calendar-filter div.calendar-select:before {
    background-color: transparent;
}

.my-calendar .rbc-header {
    padding: 10px 3px;
    background: #ff0c9c;
    color: white;
}
.my-calendar .rbc-month-view {
    background-color: #fff;
}

.my-calendar .rbc-month-view .rbc-header + .rbc-header {
    border-left: 1px solid #ff0c9c;
}

.my-calendar .rbc-toolbar {
    padding: 10px;
    background-color: #ff0c9c;
}
.my-calendar .rbc-toolbar .rbc-toolbar-label {
    font-weight: bold;
}

.my-calendar .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #f2f4f8;
}

/* .gridBookingForm {
    padding: 13px !important;
} */

.my-calendar .rbc-event {
    background-color: #ff0c9c;
    border-radius: 5px;
    padding: 0px;
}

.my-calendar a.rbc-show-more,
.my-calendar a.rbc-show-more:hover,
.my-calendar a.rbc-show-more:active,
.my-calendar a.rbc-show-more:visited {
    color: #ff0c9c;
    text-decoration: none;
}

.my-calendar .rbc-day-slot .rbc-event {
    border: 1px solid #cc830e;
}

.my-calendar .rbc-today {
    background-color: #ff0c9c23;
}

.my-calendar .rbc-selected-cell {
    background-color: #f29c1222;
}

.my-calendar .rbc-off-range-bg {
    background-color: rgba(255, 12, 156, 0.199);
}

.my-calendar .rbc-event.rbc-selected {
    background-color: #333;
}

button.extra-gig-fee-inline-button {
    /* height: 100%; */
    color: #000;
    background-color: #ff0c9c;
}

button.extra-gig-fee-inline-button:hover {
    background-color: #ff0c9caa;
}

button.extra-gig-fee-inline-button-disabled {
    /* height: 100%; */
    color: #000;
    background-color: #ccc;
}

.rbc-month-row {
    height: 100% !important;
    position: relative !important;
}

.my-calendar {
    height: 100vh !important;
}

.rbc-row-content {
    height: inherit !important;
    max-height: 100% !important;
}

.rbc-overlay > .rbc-overlay-header {
    font-size: 16px !important;
    font-weight: bold;
    line-height: 48px !important;
}

.rbc-overlay > .rbc-event {
    margin-bottom: 8px !important;
}
