

        table {
                border-collapse: collapse;
            width: 100%;
            page-break-inside: auto;
        }

        tr {
                page-break-inside: avoid;
            page-break-after: auto;
        }

        .header-left-col {
                width: 50%;
            font-size: 16px;
        }

        .header-right-col {
                width: 50%;
            text-align: right;
            align-content: flex-end;
            align-items: flex-end;
            font-size: 16px;
        }

        .bordered-cell {
                border: 1px solid black;
        }

        .invoice-table tr td {
                padding-left: 8px;
        }

        .invoice-row-date {
                width: 20%;
            font-size: 16px;
        }

        .invoice-row-time {
                width: 20%;
            font-size: 16px;
        }

        .invoice-row {
                line-height: 30px;
            min-height: 30px;
            height: 30px;
            font-size: 12px;
        }

        .invoice-row-description {
                width: 40%;
            font-size: 16px;
        }

        .invoice-row-fee {
                width: 20%;
            font-size: 16px;
        }

        .invoice-row-fee-detail {
                text-align: right;
            padding-right: 8px;
        }

        .invoice-summary-cell-left {
                text-align: left;
            padding-right: 8px;
            font-size: 16px;
        }

        .invoice-summary-cell {
                text-align: right;
            padding-right: 8px;
            font-size: 16px;
        }

        .tax-invoice {
                font-size: 36px;
            vertical-align: center;
                font-weight: 500;
        }

        .payment-to {
                font-family: LatoWeb, sans-serif;
            font-size: 14px;
            font-weight: 100;
        }

        .logo-container {
                width: 76px;
        }

        .avatar {
                border: 0px solid #fff;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
            width: 60px;
            height: 60px;
            border-radius: 60px;
            background-size: cover;
            background-position: center center;
            object-fit: cover;
        }

        .invoice-creator {
                font-size: 20px;
            font-weight: 600;
        }

        .watermark {
                width: 75%;
            position: fixed;
            top: 10%;
            left: 50%;
            margin-left: -37.5%;
            opacity: 0.5;
            z-index: 99;
            color: #BF360C;
            font-size: 88px;
            padding: 24px 8px 24px 8px;
            border: 1px solid #BF360C;
            -ms-transform: rotate(-20deg);
            -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
            text-align: center;
        }