.Welcome {
    display: block;
    padding: 20px;
    min-height: 100vh;
}

.Welcome::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: url(../img/hero3.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -webkit-filter: blur(5px) brightness(75%);
    -moz-filter: blur(5px) brightness(75%);
    -o-filter: blur(5px) brightness(75%);
    -ms-filter: blur(5px) brightness(75%);
    filter: blur(5px) brightness(75%);
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}

.Welcome #h-welcome {
    margin-bottom: 0;
    font-size: 2.5em;
    font-weight: bold;
    letter-spacing: 2px;
    color: var(--main-white);
}

.Welcome .sub-heading {
    font-size: 1.3em;
    letter-spacing: 2px;
    color: var(--shade);
}

.Welcome .main-grid {
    margin: 60px 0;
}

.Welcome .intro {
    padding: 25px;
    text-align: center;
    box-shadow: none;
}

.Welcome .stepContent {
    margin: 30px 0;
}

.Welcome .stepperHub {
    background: transparent;
}

.Welcome .stepIcon circle {
    fill: var(--main-white);
}


.Welcome .stepIcon text {
    fill: black;
}

.Welcome .stepIcon.active circle {
    fill: var(--main-pink);
}

.Welcome .stepLabel .label {
    color: var(--main-white);
}

.Welcome .stepLabel .label.active {
    color: var(--main-pink);
}

.MuiStepIcon-text-256 {
    fill: black;
}

.Welcome .stepIcon.active .MuiStepIcon-text-256 .MuiStepIcon-text-358 {
    fill: var(--main-white);
}

.jss256 {
    fill: black;
}

.jss358 {
    fill: black;
}

.jss345{
    fill: black;
}

.Welcome .stepIcon.active .jss256 {
    fill: var(--main-white) !important;
}

.Welcome .stepIcon.active.jss358 {
    fill: var(--main-white) !important;
}

.Welcome .stepIcon.active .jss345{
    fill: var(--main-white) !important;
}