.time-picker-body {
    background-color: rgba(238,238,238,255) !important;
    width: 100%;
    border: 0;
    margin: 0;
    padding-bottom: 7px;
    margin-top: -5px;
    padding-top: 7px;
    padding-left: 4px;
    font-size: 14px;
    font: inherit;
    display: block;
    min-width: 0;
    box-sizing:content-box;
}

.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 0;
}

