.scrollable-div {
    border: 1px solid #eee;
    padding: 15px;
}

/*Negotiate*/

.chat .chat-avatar {
    float: left;
}
.chat.me .chat-avatar {
    float: right;
}

.chat .chat-message {
    float: left;
    padding: 9px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #eee;
    margin-left: 15px;
    min-height: 30px;
    max-width: calc(100% - 110px);
    position: relative;
    margin-bottom: 10px;
}
.chat.me .chat-message {
    float: right;
    margin-right: 15px;
    margin-left: 0;
    margin-bottom: 10px;
}

.chat .chat-message:after {
    content: "";
    position: absolute;
    left: 0;
    top: 33px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #eee;
    border-left: 0;
    margin-top: -20px;
    margin-left: -9px;
}

.chat.me .chat-message:after {
    left: unset;
    right: 11px;
    border-right: 0;
    margin-right: -20px;
    border-left: 8px solid #eee;
    margin-left: 0;
}

.chat .chat-name {
    font-weight: 600;
    margin-bottom: 1px;
}

a.action-button:hover,
a.action-button:active,
a.action-button:visited,
a.action-button:focus {
    text-decoration: none;
    outline: none;
}

.booking-table-wrapper {
    overflow-x: auto;
}

div.select-adornment {
    background-color: transparent;
    box-shadow: none !important;
}

div.select-adornment-input {
    padding: 0 15px 0 10px;
}

.side-helper-text {
    text-align: justify;
    padding-right: 20px;
}
.bottom-helper-text {
    text-align: justify;
    padding-right: 20px;
    font-size: 12px !important;
}

.save-checkbox {
}
.save-checkbox label {
    margin-bottom: 0;
}

.save-checkbox span.checkbox-dense {
    padding: 0 6px 0 12px;
}

.summary-hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.save-checkbox > p {
    margin: 0px 28px 0;
}
