div.information-card-widget {
    height: 86px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

div.information-card-widget .caption {
    font-size: 16px;
    color: #b06f09;
}

.widget h1,
.widget h2,
.widget h3,
.widget p,
.widget span {
    font-family: "GellixThin" !important;
}

.widget-content-container {
    padding: 0 !important;
    width: 100%;
    height: 100% !important;
    overflow: hidden !important;
    background-color: #ff0c9c;
}

.widget-grid-container {
    padding: 8px !important;
    height: 96px !important;
}

.widget-information-item {
    height: 80px !important;
    background-color: #333 !important;
    align-items: center !important;
    display: flex !important;
}

.widget-icon {
    width: 100% !important;
    text-align: center;
    font-size: 2em !important;
    color: rgba(255, 255, 255, 0.5) !important;
}
