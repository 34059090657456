.widget-small-text-link-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.widget-small-text-link-title {
    background-color: #333;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.widget-small-text-link-title h2 {
    color: #fff;
    font-size: 24px;
    font-family: "GellixThin";
}

.widget-small-text-link-content {
    background-color: #fff;
    height: 100%;
    padding: 12px;
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.widget-small-text-link-content a.no-hover,
.widget-small-text-link-content a.no-hover:hover,
.widget-small-text-link-content a.no-hover:active,
.widget-small-text-link-content a.no-hover:focus {
    font-family: "Gellix";
    color: #000;
    text-decoration: none;
    outline: none;
}

.widget-small-text-link-content a,
.widget-small-text-link-content a:hover,
.widget-small-text-link-content a:active,
.widget-small-text-link-content a:focus {
    font-family: "Gellix";
    color: #000;
}

div.see-more-dialog-title {
    background-color: #ff0c9c;
}

div.see-more-dialog-title h2 {
    font-family: "GellixThin";
    color: #fff;
}

div.see-more-dialog-title h6 {
    font-family: "GellixThin";
    color: #fff;
}

div.see-more-dialog-content {
    padding: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

button.see-more-dialog-button {
    min-width: 0px;
    min-height: 0px;
    padding: 4px 8px;
    margin-top: 10px;
    margin-right: 10px;
}

button.see-more-dialog-button span {
    font-size: 12px;
}

svg.see-more-dialog-button-icon {
    width: 14px;
    height: 14px;
    margin-right: 3px;
}
