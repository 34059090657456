.card-act-name {
    font-size: 16px !important;
    font-weight: bold !important;
}

.act-background-image {
    width: 100%;
    height: 202px;
    display: flex;
    background-size: cover;
    background-position: top;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    background-color: #222222;
    position: relative;
}

.act-background-image .act-avatar {
    position: absolute;
    bottom: -26px;
    float: right;
    right: 18px;
    border: 3px solid #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    width: 60px;
    height: 60px;
}

.manage-act .act-details-act {
    max-height: 320px;
    overflow: hidden;
}

.manage-act .act-details {
    max-height: 200px;
    overflow: hidden;
}

.manage-act .act-bio {
    max-height: 170px;
    overflow: hidden;
}

.manage-act .act-action {
    margin: 0;
    padding: 8px 8px;
}

.manage-act .act-action > div {
    margin: 0;
}

.manage-act hr {
    background-color: rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
    .manage-act .act-details-act {
        height: 320px;
    }

    .manage-act .act-details {
        height: 200px;
    }

    .manage-act .act-bio {
        height: 170px;
    }

    .last-search-filter-container {
        height: 100%;
    }
}

@media (min-width: 1281px) {
    .act-background-image {
        height: 202px;
    }
}

@media (min-width: 2000px) {
    .act-background-image {
        height: 230px;
    }
}
