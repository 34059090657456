@media (min-width: 768px) {
    .dialog-div {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}


.blackText {
    color: black!important
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ff0c9c;
    color:white;
}