/* version 2 */
div.entertainment-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    color: #FFF;
    text-shadow: 0px 0px 10px #000;
    font-family: 'Rama Gothic M';
}

div.entertainment-container {
    height: 100%;
}

.image-next-event {
    width: 60vh;
    height: 40vh;
    object-fit: cover;
    margin-left: 20vh;
    border-radius: 50px;
    border-style: solid;
    border-width: thick;
    border-color: #ff0c9c;;
}

div.entertainment-slide-information-day-nextevent {
    font-family: Raleway;
    font-size: 4vh;
    margin-bottom: 2vh;
    margin-left:8vh;
}

div.entertainment-slide-information-name-nextevent {
    font-family: Raleway;
    font-size: 5vh;
    margin-top: 10vh;
    margin-left:8vh;
}

div.entertainment-slide-information-time-nextevent {
    font-family: Raleway;
    font-size: 4vh;
    margin-top: 2vh;
    margin-left:8vh;
}

.image-next-4event {
    width: 33vh;
    height: 22vh;
    object-fit: cover;
    border-radius: 25px;
    border-style: solid;
    border-width: thick;
    border-color: #ff0c9c;;
}

div.entertainment-slide-information-day-next4event {
    font-family: Raleway;
    font-size: 3vh;
    margin-bottom: 1vh;
    margin-left:4vh;
}

div.entertainment-slide-information-name-next4event {
    font-family: Raleway;
    font-size: 4vh;
    margin-top: 3.5VH;
    margin-left:4vh;
}

div.entertainment-slide-information-time-next4event {
    font-family: Raleway;
    font-size: 2.5vh;
    margin-top: 1vh;
    margin-left:4vh;
}

div.entertainment-left-image {
    background-color: #666;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    -o-background-size: cover;
    /* -webkit-transition: 2s ease-in;
    transition: 2s ease-in; */
}

div.entertainment-left-image-hero {
    background-image: url(../../img/hero2.jpg);
    background-color: #666;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    -o-background-size: cover;
}

div.entertainment-right-content {
    padding-left: 3vw;
}

img.entertainment-curve {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 49.5%;
}

div.entertainment-big-title {
    font-family: "RalewayRoman", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 18vh;
    color: #ff0c9c;
    margin-top: 8vh;
    margin-bottom: -4vh;
    margin-left: -1.5vh;
}

div.entertainment-big-title-nextevent {
    font-family: Raleway;
    font-weight: bold;
    font-size: 10vh;
    color: #ff0c9c;
    margin-top: 8vh;
    margin-left: 20vh;
    height: 0px;
}



div.entertainment-slide-information-day {
    font-size: 7vh;
    margin-bottom: -1.5vh;
}

div.entertainment-slide-information-name {
    font-size: 10vh;
    margin-top: -1.5vh;
    margin-bottom: -1.5vh;
}




div.entertainment-slide-information-time {
    font-size: 7vh;
    margin-top: -1.5vh;
}



div.entertainment-follow-us {
    font-family: "RalewayRoman", Arial, Helvetica, sans-serif;
    position: absolute;
    right: 7vh;
    bottom: 7vh;
    font-size: 4vh;
}

div.entertainment-follow-us-nextevent {
    font-family:Raleway;
    position: absolute;
    right: 7vh;
    bottom: 7vh;
    font-size: 2vh;
}

div.entertainment-follow-us-nextevent  img {
    height: 6vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

div.entertainment-follow-us img {
    height: 12vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

div.entertainment-venue-profile-image {
    position: absolute;
    top: 7vh;
    right: 7vh;
    height: 25vh;
    border-radius: 50%;
    width: 25vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

.entertainment-venue-profile-image-nextevent {
    position: absolute;
    top: 7vh;
    right: 7vh;
    height: 18vh;
    border-radius: 50%;
    width: 18vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}


img.entertainment-venue-profile-image {
    position: absolute;
    top: 7vh;
    right: 7vh;
    height: 25vh;
    border-radius: 50%;
    width: 25vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

div.background-image-control.active {
    background-color: rgba(255, 12, 156,  0.9);
    border: 1px solid rgba(255, 12, 156,  1);
}

div.background-image-control.inactive {
    background-color: rgba(255, 12, 156,  0.6);
    border: 1px solid rgba(255, 12, 156,  0.7);
}

div.background-image-control-main-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}


div.background-image-control-container {
    display: flex;
    justify-content: center;
    padding-bottom: 26px;
}

/* version 1 */
/* div.entertainment-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #222222;
    color: #FFF;
    text-shadow: 0px 0px 10px #000;
    font-family: 'Rama Gothic M';
    
    background-image: url(../../img/welcome-grayscale-blur.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    -o-background-size: cover;
}

div.entertainment-container {
}

div.entertainment-big-title {
    font-size: 5vw;
    padding-left: 1.5vw;
    padding-top: 0.5vh;
}

div.entertainment-slides-container {
    height: 75vh;
    padding: 20px;
}

div.entertainment-slide {
    height: 100%;
}

div.entertainment-slide-picture-container {
    height: 100%;
}

.entertainment-slide-frame-image {
    height: 72vh;
    width: 46vw;
    position: absolute;
    z-index: 1;
    margin-left: 9vw;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

div.entertainment-slide-profile-image-container {
    position: relative;
    top: 10vh;
    left: 14vw;
    height: 52vh;
    width: 36vw;
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    -o-background-size: cover;

    border: 65px solid black;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.35));
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.35));
}

div.entertainment-slide-profile-image-container:before {
    content: " ";
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    border: 50px solid white;
}

div.entertainment-slide-profile-image-container-hero {
    position: relative;
    top: 10vh;
    left: 14vw;
    height: 52vh;
    width: 36vw;
    background-image: url(../../img/hero2.jpg);
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center;
    -o-background-size: cover;

    border: 65px solid black;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.35));
    filter: drop-shadow(0px 0px 10px rgba(255,255,255,0.35));
}

div.entertainment-slide-profile-image-container-hero:before {
    content: " ";
    position: absolute;
    top: -50px;
    left: -50px;
    right: -50px;
    bottom: -50px;
    border: 50px solid white;
}

div.entertainment-slide-information-container {
    display: flex;
    align-items: center;
    padding-left: 4vw;
}

div.entertainment-slide-information-day {
    font-size: 7vh;
}

div.entertainment-slide-information-name {
    font-size: 5vh;
}

div.entertainment-slide-information-time {
    font-size: 5vh;
}

div.entertainment-follow-us {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 4vh;
}

div.entertainment-follow-us img {
    height: 8vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
}

div.entertainment-venue-profile-image {
    position: absolute;
    top: 5vh;
    right: 5vh;
    height: 25vh;
    width: 25vh;
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.5));
} */