ol.breadcrumb {
    padding: 0px 0px;
    margin-bottom: 8px;
}

span.breadcrumb-icon {
    font-size: 18px;
    margin-right: 4px;
    position: relative;
    top: 4px;
}

svg.breadcrumb-icon {
    font-size: 18px;
    margin-right: 4px;
    position: relative;
    top: 3px;
}

.breadcrumb {
    background: transparent !important;
}

li.breadcrumb-item a,
li.breadcrumb-item a:hover,
li.breadcrumb-item a:active,
li.breadcrumb-item a:visited {
    color: #fff;
    text-decoration: none;
}

li.breadcrumb-item span{
    color: #ff0c9c;
}

.breadcrumb > li + li::before{
    color: #fff;
}