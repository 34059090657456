.font-date {
    font-weight: 200 !important;
}

.td-width-custom {
    padding: 8px 23px 4px 21px !important;
}

.td-custom {
    font-size: 0.5rem !important;
    margin: 0rem
}

.th-custom {
    font-size: 0.7rem;
}

.preview-table {
    border: 1px dotted;
    
}



.embed-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-color: white;
    color: white;
    text-align: center;
}

.gig-column {
    /* padding: 4px 0px -1px 24px !important; */
    padding: unset !important;
}

td.date-gig-column {
    width: 25% !important;
    padding: 0px !important;
    /* padding: 0% !important; */
}


.gridLayoutMini {
    border: 'solid';
    width:100%;
    display: 'inline-flex';
    padding: 10px;

}

.gridItemMini {
    text-align: 'center';
    border: 'solid';
    display: 'inline-flex';
    border-radius: 10px;
    width: 100%;
    aspect-ratio: 1/1;
}
.imageItemMini{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    border-radius: 10px !important;
    background-color: rgb(0, 0, 0);
}

.imageStyleMini {
    width:100%;
    aspect-ratio: 1/1 !important;
    object-fit: cover;
    z-index: 1;
}

.topcornerMini{
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
 }

 .bottomTextMini{
    position: absolute;
    bottom: 5px;
    left: 5%;
    width: 80%;
    flex-wrap: wrap;
    color: white;
    z-index: 3;
 }

 .bottomRightCornerMini{
    position: absolute;
    bottom: 10px;
    right: 6px;
    width:8px;
    height:8px;
    flex-wrap: wrap;
    color: white;
    z-index: 3;
 }



.imageItemDetailMini{
    flex-shrink: 0;
    width: 200px;
    height: 200px;
    border-radius: 5px !important;
    background-color: rgb(0, 0, 0);
    align-items: center;
    justify-content: center;
}

.imageStyleDetailMini {
    width: 200px;
    height: 200px;
    object-fit: cover;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,1) 100%);
    
}

.gridInfoMini{
    display: 'inline-flex';
    padding: 30px;
    height: 100%;
    min-width:100px;
    /* min-width: 400px; */
}

.genreContainerMini{
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 5px;
    /* max-width: 400px; */
}
.genreWrapperMini{
    height: 15px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 1px;
    display: flex;
    flex-wrap: wrap;
    border-width: 1px;
    padding: 2px;
    font-size: 7px;
    font-family:"Open Sans";
}
.buttonStyleMini{
    border-radius: 5px !important;
    border-width: 1px !important;
    height: 10px !important;
    min-width:45px !important;
    max-width:45px !important;
    font-size: 4px !important;
    font-family:"Open Sans" !important;
}