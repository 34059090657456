@media screen and (orientation:portrait) { 
    .Landing {
        background-color: #000000;
        background-image: url(../img/hero_potrait.png);
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        background-size: 100% 100%;
        -o-background-size: contain;
        min-height: 100vh;
    }
}
@media screen and (orientation:landscape) {
    .Landing {
        background-color: #000000;
        background-image: url(../img/hero3.png);
        background-position: center center;
        background-repeat: no-repeat;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        background-size: 100% 100%;
        -o-background-size: contain;
        min-height: 100vh;
    }
 }



.flex {
    display: flex;
    flex: 1;
}

.flex a {
    display: inline-block;
    width: 150px;
    padding: 8px 0;
}

.flex a img {
    object-fit: contain;
    max-width: 100%;
}
