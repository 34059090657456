.table-paper {
    width: 100%;
    overflow-x: auto;
}

.table {
    min-width: 700px;
}

.table-paper thead,
.table-header {
    background-color: #4d4d4d;
}

.table-paper th,
.table-header th {
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
}

.table-paper td {
    font-family: "GellixThin";
}

.table-icon {
    color: #ff0c9c;
    font-size: 18px;
}
