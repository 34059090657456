.post-paper {
    margin-top: 8px;
}

.preview-paper {
    margin-top: 8px;
    margin-left:25px;
}

.social-post-container {
    padding: 10px;
    /* background-color: #262626; */
}
.select-social-channels {
    height: 40px;
    width:40px;
    margin-left: 5px;
    cursor: pointer;
}

.gig-image-social {
    position: relative;
    width: fit-content;
}
.img-container-crop {
    position: 'relative';
    flex: 1;
    padding: 16;
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
}
.gig-image-social .check-mark {
    position: absolute;
    bottom: 8px;
    right: 10px;
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: #1fd655;
}

.cropButton {
    flex-shrink: 0;
    margin-left: 16;
}

.upload-image {
    height: 25px;
    width:25px;
    padding-right: 5px;
    margin-left: 10px;

}

.small-caption {
    font-size: 0.6rem !important;
}
.instagram-title-preview {
    height: 40px;
    width:40px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 50%;
}

.upload-gallery {
    height: 25px;
    width:40px;
    padding-right: 5px;
    margin-left: 10px;

}
.inside-paper-0 {
    padding-right: 10px !important;
    padding-left:25px !important;;
}
.inside-paper {
    padding: 25px !important;
    padding-right: 10px !important;
}

.inside-paper-2 {
    padding-top:20px;
    padding-left:25px;
}

.inside-paper-3 {
    padding: 25px;
    padding-left:25px;
}

.caption-text {
    opacity: 0.5;
    font-size: 0.575rem !important;
}



.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  
 
  @media (max-width: 479px) {
      .second-button-social {
        font-size:12px !important;
        width:200px !important;
        margin-top:20px !important;
      }
    
      .second-arrow-social {
        margin-top:20px !important;
      }
      .preview-drop-down{
        padding-right: 10px;
        padding-left: 10px;
        max-width:388px;
       }
      .hide-mobile {
        display: none;
      }
      .imageStyleDetailSocial {
        min-width: 250px;
        min-height: 250px;
        object-fit: cover;
        max-width: 250px;
        max-height: 250px;
        z-index: 1;
        cursor: pointer;
    }
    
    .imageStyleDetailSocial {
        min-width: 250px;
        min-height: 250px;
        object-fit: cover;
        max-width: 250px;
        max-height: 250px;
        z-index: 1;
        cursor: pointer;
    }
}

@media (min-width: 480px) {
    .second-button-social {
        font-size:12px !important;
        width:200px !important;
        margin-left:20px !important;
        
      }

     .preview-drop-down{
        width:388px;
        margin-left:10px
     }
      .hide-desktop{
        display: none;
      }
      .imageStyleDetailSocial {
        min-width: 300px;
        min-height: 300px;
        object-fit: cover;
        max-width: 300px;
        max-height: 300px;
        z-index: 1;
        cursor: pointer;
    }
    
    .imageStyleDetailSocial {
        min-width: 300px;
        min-height: 300px;
        object-fit: cover;
        max-width: 300px;
        max-height: 300px;
        z-index: 1;
        cursor: pointer;
    }
}

