.widget-title {
    background: #434343;
    padding: 12px;
    display: flex;
    flex-direction: row;
}

.widget-title span.widget-title-text {
    color: #ffffff;
    font-size: 24px;
    font-family: "GellixThin" !important;
    display: flex;
    flex-grow: 1;
}

div.label-danger {
    background-color: #e74c3c;
    color: #fff;
    font-family: "GellixThin" !important;
    display: flex;
    width: 35px;
}

@media screen and (max-width: 1280px) {
    .widget-title span.widget-title-text {
        font-size: 21px;
    }

    .widget-title {
        padding: 8px;
    }
}
