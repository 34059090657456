div.information-card-widget {
    height: 86px;
}

div.information-card-widget-paper {
    height: 100%;
    background-color: #ff0c9c;
}

div.information-card-widget-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

div.information-card-widget .content-number {
    font-size: 30px;
    font-family: "Gellix";
    color: #333;
    margin: -8px 0px;
    text-align: left;
    opacity: 0.8;
}

div.information-card-widget .content-caption {
    font-size: 16px;
    font-family: "Gellix";
    color: #fffa;
    text-align: left;
}

div.information-card-icon-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 40px;
    background-color: #333;
    color: #fff6;
}

div.information-card-content-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100%;
    padding-left: 10px;
}
