div.booking-badge {
    border-radius: 4px !important;
    color: #fff !important;
    font-size: 12px;
    padding: 1px 0px 2px 0px;
    height: 28px;
}

div.booking-badge span {
    padding-left: 8px;
    padding-right: 8px;
}

.booking-SentToAct-badge, .booking-New-badge {
    background-color: #1565c0 !important;
}

.booking-Sent-badge {
    background-color: #1565c0 !important;
}

.booking-Draft-badge {
    background-color: #64b5f6 !important;
}

.booking-Accepted-badge {
    background-color: #f1f7f1 !important;
}

.booking-Incomplete-badge, .booking-PlayerDeclined-badge,
.booking-AllPlayerConfirmed-badge, .booking-ClientNotified-badge, .booking-PlayerInvitedAndClientNotified-badge,
.booking-PlayerNotified-badge {
    background-color: #1b5e20 !important;
}

.booking-Confirmed-badge, .booking-PlayerConfirmedAndClientNotified-badge, .booking-Accepted-badge {
    background-color: #43a047 !important;
}

.booking-Declined-badge {
    background-color: #b71c1c !important;
}

.booking-Resent-badge {
    background-color: #b19cd9 !important;
}

.booking-Completed-badge {
    background-color: #bdbdbd !important;
}

.booking-Cancelled-badge {
    background-color: #b71c1c !important;
}

.booking-CancelledByAct-badge {
    background-color: #b71c1c !important;
}

.booking-CancelledByActHistory-badge {
    background-color: #b71c1c !important;
}

.booking-Negotiate-badge {
    background-color: #ff0c9c !important;
}

.tooltip-wrapper {
    display: inline-block; /* display: block works as well */
    margin: 50px; /* make some space so the tooltip is visible */
  }
  
  .tooltip-wrapper .btn[disabled] {
    /* don't let button block mouse events from reaching wrapper */
    pointer-events: none;
  }
  
  .tooltip-wrapper.disabled {
    /* OPTIONAL pointer-events setting above blocks cursor setting, so set it here */
    cursor: not-allowed;
  }
