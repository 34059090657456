.avatar {
    width: 50px;
    height: 50px;
}

.drawer-temporary {
    width: 230px;
    background-color: #262626 !important;
}

div.drawer-temporary {
    -webkit-overflow-scrolling: auto;
}

.drawer-temporary > span {
    background-color: #262626 !important;
    z-index: 5;
}

.drawer-permanent {
    width: 235px;
    margin-top: 64px;
    background-color: #262626 !important;
}

.drawer-permanent h3,
.drawer-permanent span,
.drawer-permanent p,
.drawer-permanent a,
.drawer-temporary h3,
.drawer-temporary span,
.drawer-temporary p,
.drawer-temporary a {
    color: #aaa;
    text-decoration: none;
}

.drawer-permanent li:hover h3,
.drawer-permanent li:hover span,
.drawer-permanent li:hover i,
.drawer-permanent li:hover p,
.drawer-permanent li:hover a,
.drawer-temporary li:hover h3,
.drawer-temporary li:hover span,
.drawer-temporary li:hover i,
.drawer-temporary li:hover p,
.drawer-temporary li:hover a,
.drawer-permanent a:hover h3,
.drawer-permanent a:hover span,
.drawer-permanent a:hover i,
.drawer-permanent a:hover p,
.drawer-permanent a:hover a,
.drawer-temporary a:hover h3,
.drawer-temporary a:hover span,
.drawer-temporary a:hover i,
.drawer-temporary a:hover p,
.drawer-temporary a:hover a {
    color: #fff;
    text-decoration: none;
}
.menu-li {
    background-color: #262626;
    z-index: 5;
}
.stone-and-wood-logo {
    position: absolute;
    bottom: 94px;
    left: 25%;
    max-width: 50%;
    text-align: center;
    color: #aaa;
    z-index: 3;
}

.stone-and-wood-logo.mobile {
    bottom: 40px;
    z-index: 3;
}

.stone-and-wood-logo img {
    max-width: 100%;
}
