.roster-profile-image {
    height: 80px !important;
    width: 80px !important;
}

.roster-card-container {
    width: 250px;
    max-width: 250px;
    height: 100%;
    position: relative !important;
}

.roster-card-header {
    padding-bottom: 0px !important;
}

.roster-card-rank {
    position: absolute !important;
    top: 12px !important;
    left: 10px !important;
}

.roster-card-content-container {
    padding-top: 0 !important;
}

.roster-instrument-chip {
    margin-right: 2px;
    background-color: #ff0c9c !important;
}

.status-badge {
    border-radius: 4px !important;
}

.badge-accepted {
    background-color: #ff0c9c !important;
}

.badge-pending {
    background-color: #b3e5fc !important;
}

.badge-invited {
    background-color: #bdbdbd !important;
}

.roster-act-details {
    padding: 32px 24px 24px 24px !important;
}

.roster-act-reorder {
    cursor: -webkit-grab !important;
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 100;
}

.roster-act-badge {
    position: absolute !important;
    top: 24px;
    left: 24px;
    z-index: 100;
}
