button.card-top-right-button {
    position: absolute;
    float: right;
}

div.edit-testimonial-card-header {
    padding: 8px 16px;
    background-color: #ff0c9c;
    color: #fff !important;
}

div.edit-testimonial-card-header div[class*="action"] {
    margin-top: 0px;
    margin-bottom: 0px;
}
div.edit-testimonial-card-header span {
    font-size: 1.1rem;
}

div.edit-testimonial-card-content {
    padding: 8px 16px 16px 16px;
}

.dropzone {
    border: 3px dashed #ff0c9c;
    padding: 20px;
    height: 200px;
    width: 200px;
    align-items: center;
    align-content: center;
    display: flex;
    text-align: center;
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    ); */
}

.dropzone.insurance-doc {
    padding: 20px;
    height: auto;
    min-height: 50px;
    width: 100%;
    cursor: pointer;
}

.dropzone-gridlist {
    border: 3px dashed #ff0c9c;
    padding: 15px;
    height: 100%;
    width: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    text-align: center;
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    ); */
}

.add-testimonial-card {
    border: 3px dashed #ff0c9c;
    padding: 15px;
    height: 100%;
    width: 100%;
    min-height: 250px;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    text-align: center;
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    ); */
}

.add-testimonial-card:hover {
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156,  0.1),
        rgba(255, 12, 156,  0.1) 20px,
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.2) 40px
    ); */
    cursor: pointer;
}

.edit-testimonial-action-wrapper {
    padding-right: 27px;
}

.add-testimonial-card svg {
    font-size: 3em;
    color: rgba(255, 12, 156,  0.8);
}

.add-testimonial-card:hover svg {
    color: rgba(255, 12, 156,  0.6);
}

.profile-pic .dropzone {
    border-radius: 50%;
    cursor: pointer;
    /* background: url(../../img/blank-profile.png) no-repeat;
    background-size: cover; */
    margin: auto;
    margin-bottom: 40px;
}

.profile-pic .dropzone p {
    margin: 0;
    text-align: center;
    width: 100%;
}

.profile-pic > div {
    position: relative;
    margin: auto;
}

.profile-pic .avatar {
    width: 200px;
    height: 200px;
    margin: auto;
}

.profile-pic-container {
    position: relative;
    width: fit-content;
}

.profile-pic-container-empty {
    position: relative;
    width: fit-content;
    width: 50px;
    height: 50px;
    margin: auto;
}

.profile-pic-container .del-avatar {
    position: absolute;
    bottom: 8px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: #ff0050;
}

.gridPublicToggle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-pic .dropzone {
    width: 100%;
}

.background-pic .tilebar {
    color: #fff;
}

.background-pic .del {
    color: #fff;
}

.background-pic ul.gridlist li {
    width: 100% !important;
    margin-bottom: 12px;
}

.background-pic ul.gridlist li:last-child {
    margin-bottom: 0;
}

.prof-pic-progress {
    position: absolute;
    top: -20px;
    left: -20px;
    margin: auto;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

div.sticky-information {
    position: -webkit-sticky;
    position: sticky;
    top: 85px;
}

div.background-image-linear-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

button.button-small {
    padding: 0px 6px;
    min-height: 24px;
}

button.button-small p {
    font-size: 0.78rem;
}

button.button-small svg {
    font-size: 18px;
}

button.fullscreen-bio {
    top: 0;
    right: 0;
    padding: 6px;
    background-color: var(--shade);
    text-transform: none;
    border-radius: 4px;
}

.profile-pic-container {
    position: relative;
}

@media (min-width: 768px) {
    div.sticky-information.padded {
        margin-top: 39px;
    }
    .profile-pic .avatar {
        position: relative;
        margin: 0;
    }
    .profile-pic .prof-pic-progress {
        margin: 0;
    }
    .profile-pic .dropzone {
        margin: 0;
    }
    button.fullscreen-bio {
        top: 39px;
        right: 7px;
        background-color: var(--main-white);
    }
    .background-pic .dropzone {
        width: 200px;
    }
    .background-pic ul.gridlist {
        width: 200px !important;
    }
    .gridPublicToggle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .profile-pic > div {
        margin: 0;
    }
}

@media (max-width: 1018px) {
    .switch-venue-general {
        display: flex;
        flex-direction: column;
    }
}
