.columnSeparator {
    width: 20px;
}

.song {
    padding: 16px;
    min-height: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .th-songlist {
    background-color: #4d4d4d;
} */

/*
.th-songlist>span {
    color: aliceblue;
} */

/* .head-title {
    color: aliceblue !important;
} */
