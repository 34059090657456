.facebook-message-post textarea {
    height: 200px !important;
}

.facebook-message-post-title {
    background-color: #333333 !important;
}

.facebook-message-post-title h2 {
    color: white !important;
}


.facebook-message-sub-title{
    color: rgba(0, 0, 0, 0.80) !important;
    font-size: 18px !important;
}
.facebook-message-sub-title-normal{
    color: rgba(0, 0, 0, 0.70) !important;
    margin-left: 10px;
}
.facebook-back-image {
    margin-left: auto;
    margin-right: auto;
    display: block;
}
