.Login {
    display: flex;
    padding: 20px;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    position: relative;
    overflow: hidden;
}

.Login .header-logo {
    display: flex;
    position: absolute;
    width: 250px;
    height: auto;
}

.Login .image-background {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    width: 100vw;
    height: 100%;
    z-index: -40;
    background: url("../img/hero3.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -webkit-transform: scale(1.03);
    -moz-transform: scale(1.03);
    -o-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}

.Login .Login-Grid {
    align-items: center;
    justify-content: center;
    display: flex;
    align-content: center;
}

.Login .Form {
    z-index: 12;
    display: flex;
    width: 500px;
    padding: 60px 60px 60px 60px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 36px;
    background-color: rgba(255, 255, 255, 0.1);
}

.Login .logo-login {
    width: auto;
    flex-direction: row;
    z-index: 10;
    margin-bottom: -28px;
    padding-top: 30px;
}

.Login .Form .form-submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login .logo-login .logo-login-title {
    font-size: 40px;
    color: #fff;
    margin-right: 20px;
    margin-bottom: 50px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.Login .logo-login img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 16px;
}

.Login .logo-crete-new-pw img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: 20px;
}

button.fb-btn {
    width: 100%;
    height: 50px;
}

.Submit-form .login-text {
    font-size: 16px;
    font-family: "Gellix";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    text-align: center;
}

.Submit-form .signup-desc {
    font-size: 15px;
    font-family: "Gellix";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    margin-top: 10px;
    text-align: justify;
}

.Submit-form .signup-text-field {
    font-size: 14px;
    font-family: "Gellix";
    margin-bottom: 0px;
    color: rgb(255, 255, 255);
}

button.fb-share {
    background-color: #4267b2;
    color: #ffffff;
    border-radius: 3px;
    padding: 8px 16px;
    float: left;
    width: 100%;
}

button.fb-share-social {
    background-color: #4267b2;
    color: #ffffff;
    border-radius: 3px;
    padding: 8px 16px;
    float: left;
    width: 100%;
    font-size: 14px;
    font-family: Gellix, sans-serif;
  
}

button.fb-share-disabled {
    background-color: #a3a3a3;
    color: #ffffff;
    border-radius: 3px;
    padding: 8px 16px;
    float: left;
    width: 100%;
}

.page-indicator {
    background-color: var(--shade);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.page-indicator a {
    width: 100%;
    padding: 15px;
    color: var(--main-black);
    display: inline-block;
    text-align: center;
}

.page-indicator a.active {
    color: var(--main-pink);
    background-color: var(--main-white);
    font-weight: 700;
}

.page-indicator a:hover {
    text-decoration: none;
    font-weight: 700;
}

button.fb-login {
    background-color: var(--fb);
    color: var(--main-white);
    border-radius: 3px;
}

.or-container {
    overflow-x: hidden;
    margin-top: 25px;
    margin-bottom: 10px;
}

.or {
    margin-top: 20px;
    text-align: center;
    padding: 5px;
    position: relative;
    height: 30px;
}

.or span {
    background-color: var(--main-white);
    padding: 0 5px;
    z-index: 2;
    position: absolute;
    left: 47%;
}

.or::before {
    content: " ";
    position: absolute;
    border-bottom: 1px solid var(--shade);
    top: 50%;
    width: 100%;
    overflow: hidden;
    left: 0;
    z-index: 1;
}

.Submit-form {
    z-index: 12;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.Submit-form .agreement-text {
    font-size: 14px;
    color: white;
    align-self: center;
    padding-top: 8px;
    cursor: pointer;
}

/* .Submit-form .inputTextContainer {
    background-color: rgba(0, 0, 0, 0.2);
} */

.Submit-form .inputTextField {
    background: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 0;
    border: 1px solid white;
    
}

.Submit-form .inputTextContainer ::placeholder {
    color: white;
    opacity: 0.5;
}

.Submit-form .inputTextContainer > label {
    color: white;
    opacity: 1;
}



/* .Submit-form .MuiInputBase-input-170 {
    background-color: red;
} */

.Submit-form .btnSubmit {
    color: var(--main-white) !important;
    font-size: 28px;
    font-family: "RalewayRoman", Arial, Helvetica, sans-serif;
    color: rgb(255, 255, 255);
    font-weight: bold;
    width: 170px;
    height: 60px;
    border-radius: 0;
}

.Submit-form .btnSubmit:disabled {
    background: #b5b5b5;
}

.btnForget {
    font-size: 14px;
    margin-top: 15px;
    color: var(--main-white);
    width: 100%;
    text-align: center;

    display: inline-block;
}

.btnForget:hover {
    color: var(--main-white);
    text-decoration: none;
}

.Submit-form .btnResetPass {
    color: var(--main-white) !important;
    font-size: 28px;
    font-family: "RalewayRoman", Arial, Helvetica, sans-serif;
    color: rgb(255, 255, 255);
    font-weight: bold;
    /* width: 170px; */
    height: 60px;
    border-radius: 0;
}

.link-tos {
    color: #ff0c9c;
}

.whiteColor {
    color: white;
}

@media screen and (max-width: 600px) {
    .Login .header-logo {
        width: 30vw;
        height: auto;
        max-width: none;
        min-width: none;
    }

    .Login .logo-login {
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
    }

    .Login .Form {
        width: 90vw;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}
