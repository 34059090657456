div.multi-select-chip {
    background-color: rgba(255, 12, 156,  0.87);
    border-radius: 4px;
    margin: 5px;
    margin-right: 0;
    margin-bottom: 0;
    color: #fff;
}

.fo .Select--multi {
    padding: 0px !important;
    min-height: 42px;
}

.fo .Select--multi .Select-arrow-zone,
.fo .Select--multi .Select-clear-zone {
    height: 42px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Select-multi-value-wrapper {
    margin-bottom: 5px;
}
