@import "../../var.css";

.type {
    margin: 15px;
}

.type button {
    width: 250px;
    overflow: hidden;
}

.type.goToDashboard button {
    width: 100%;
    height: unset;
    color: var(--main-white);
    background: none;
    padding: 8px 16px;
    background-color: var(--main-pink);
}

.type button [type="subheading"] {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.type button [type="subheading"] .type-image {
    width: 120px;
    height: 120px;
    border: solid 3px var(--main-white);
    border-radius: 100%;
    margin-bottom: 30px;
    padding: 25px;
}

.type button:hover [type="subheading"] .type-image {
    background: var(--main-white);
    filter: unset;
}

.type button [type="subheading"] img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.type button:hover [type="subheading"] img {
    -webkit-filter: brightness(0);
    -moz-filter: brightness(0);
    -o-filter: brightness(0);
    filter: brightness(0);
}

.type button [type="subheading"] .title {
    color: var(--main-white);
    padding: 5px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.type.goToDashboard [type="subheading"] .title {
    font-size: 1.5em;
    padding: 15px;
    padding-bottom: 0;
}

.type button [type="subheading"] .subtitle {
    display: inline-block;
    text-transform:none;
    color: var(--main-black);
    font-size: 1.1em;
    letter-spacing: 1px;
    color: var(--main-white);
    padding-bottom: 10px;
}

.type.goToDashboard [type="subheading"] .subtitle {
    font-size: 1.3em;
}

@media (max-width: 960px) {
    .type button [type="subheading"] .subtitle {
        color: var(--shade);
    }
}
