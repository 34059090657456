.signature-label {
    display: block;
    float: left;
    font-weight: normal;
    position: relative;
    top: 19px;
    background: white;
    left: 13px;
    padding: 5px;
    margin-top: -27px;
    transform: translate(-30px, 1px) scale(0.75);
}

.sigCanvas {
    display: block;
    float: left;
    border: 1px solid rgba(0, 0, 0, 0.27);
    border-radius: 3px;
    clear: left;
}

.signature-button {
    font-size: 90%;
}
