.paper-tutorial {
    padding: 15px;
}

.paper-tutorial h3 {
    margin: 3px 0 12px;
}

.paper-tutorial .action {
    text-align: right;
}

.org-form .action {
    text-align: right;
}

.userTable-row td,
.userTable-row th {
    padding-right: 24px;
}

.org-paper-form {
    padding: 24px;
}

.org-form {
    padding: 16px;
}

.state-block {
    border-left: 1px solid #ddd;
    padding-top: 24px;
    padding-bottom: 24px;
}

.state-block > div {
    margin-top: 24px;
}

.state-block > div:first-child {
    margin-top: 0;
}

.state-block .region-table tr td:last-child {
    padding-right: 0;
}

@media (max-width: 767px) {
    .org-paper-form {
        padding: 8px;
        margin: 0 -8px;
    }
}

.org-divider {
    border-top: 3px solid #ff0c9c;
}

.org {
    padding: 16px;
    position: relative;
}

.orgHead {
    margin-bottom: 16px;
}

.org.state {
    margin-left: 32px;
    margin-bottom: 16px;
}

.org.state:before {
    content: "";
    position: absolute;
    width: 4px;
    height: calc(100% + 16px);
    background: var(--main-pink);
    top: 0;
    left: -20px;
}

.org.state:first-child:before {
    height: calc(100% + 32px);
    top: -16px;
}

.org.state:last-child:before {
    height: calc(50% + 2px);
}

.org.state:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 4px;
    background: var(--main-pink);
    top: calc(50% - 2px);
    left: -16px;
}

@media (min-width: 860px) {
    .org {
        margin-right: 16px;
    }

    .org:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 4px;
        background: var(--main-pink);
        top: calc(50% - 2px);
        right: -16px;
    }

    .org:before {
        content: "";
        position: absolute;
        width: 4px;
        height: -moz-available;
        height: stretch;
        background: var(--main-pink);
        top: calc(50% - 2px);
        right: -16px;
    }

    .org.state {
        margin-right: 0;
        margin-left: 0;
    }
    .org.state:first-child:before {
        height: calc(50% + 18px);
        top: calc(50% - 2px);
    }
}
