.setlist-dialog-title {
    background-color: #333333 !important;
}

.setlist-dialog-title > h2 {
    color: whitesmoke;
}

.facebook-dialog-title {
    background-color: #ff0c9c !important;
    color: whitesmoke;
}

.facebook-dialog-title > h2 {
    color: whitesmoke;
}


.facebook-dialog-text-title {
    color: whitesmoke;
    font-size: 1.25rem;
    font-family: OpenSans, sans-serif;
    font-weight: 500;
    line-height: 1.6;
}

