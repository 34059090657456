.booking-date-picker > div {
    height: 41px;
}
.booking-date-picker button {
    height: 40px;
    width: 40px;
}

.overflow-hidden{
    position: unset !important;
}

.see-more-link{
    cursor: pointer;
    margin-top: 20px;
    margin-right: 10px;
    font-size: small;
    right: 0;
    position: absolute;
}

.see-more-link:hover{
    text-decoration: none;
}

.hide-outline fieldset{
    border: 0px solid;
}

.hide-outline p:first-child {
    margin-top: -25px;
}
