.header-embed {
    z-index: 1;
    position: fixed !important;
    background-color: white;
    top: 0;
    padding-top: 20px;
    padding-bottom: 10px;
    width: 100% !important;
}

.header-embed h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70% !important;
}

.gig-table-container {
    margin-top: 72px !important;
}

.space-top-10 {
    padding-top: 20px;
}

.space-top-12 {
    padding-top: 12px !important;
}
 .preview-table {
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.MuiTableCell-body-37 {
    font-size: 1rem;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    .preview-table {
        border: 0;
        padding-left: 24px;
        padding-right: 24px;
    }
  }
 