.align-right {
    text-align: right;
}

.button-date-filter {
    background-color: #ff0c9c !important;
    font-family: "GellixSemiBold" !important;
    color: hsl(37, 90%, 41%) !important;
    border-radius: 2px !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    min-width: unset !important;
    padding: 8px !important;
}

.button-date-filter:last-child {
    margin-right: 0 !important;
}

.button-date-filter.active,
.button-date-filter:hover {
    background-color: #404040 !important;
    font-family: "GellixSemiBold" !important;
    color: #fff !important;
}

.button-date-filter.active::after {
    content: "";
    left: 0;
    right: 0;
    top: 35px;
    margin: auto;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #404040;
}

.button-filter {
    background-color: #ff0c9c !important;
    color: #c87f0a !important;
    font-family: "GellixSemiBold" !important;
}

.button-filter:hover {
    opacity: 0.9;
    color: #333 !important;
}

.border-bottom-grid {
    border-bottom: 1px solid #e1e6ef;
    padding-bottom: 16px;
}
