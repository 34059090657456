div.stepperContent {
    padding: 24px;
    box-shadow: none;
}

div.stepperXs {
    padding: 16px;
    overflow: hidden;
    justify-content: center;
}

.stepper-xs-step {
    padding-right: 0 !important;
}

.stepper-xs-icon {
    padding-right: 0 !important;
}

.stepper-xs-icon svg {
    width: 0.9em;
    height: 0.9em;
}

div.stepperContent .action {
    margin-top: 20px;
    text-align: right;
}

@media (max-width: 767px) {
    div.stepperContent {
        padding: 16px;
        /* margin: 0 -8px; */
    }
}
