.privateHire-edit-form {
    /* display: block;
    padding: 20px;*/
    background-color: #3A3B3D;
    
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover; */
    min-height: 100vh;
}
