.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: "OpenSans, sans-serif";
    font-size: 14px;
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .a {
  /* color: linear-gradient(to bottom, #6a11cb 0%, #2575fc 100%); */
    /* color: radial-gradient(#16d9e3 0%, #30c7ec 47%, #46aef7 100%); */
    color: red;
  }

  a:link,
a:visited {
  /* color: linear-gradient(to bottom, #6a11cb 0%, #2575fc 100%); */
  color: radial-gradient(#16d9e3 0%, #30c7ec 47%, #46aef7 100%);
}
  
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    /* margin-bottom: 5px; */
    color: #666;
    user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #333;
    background-color: #fff;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    /* display: inline-block; */
  }
  
  .RichEditor-activeButton {
    color: #ff0c9c;
  }