button.tab-selected {
    background-color: #fff;
}

div.tabs-root {
    background-color: #eee;
}

span.tabs-indicator {
    background-color: #fff;
    height: 0px;
}

div.status-chip {
    height: 20px;
    font-size: 0.8em;
    text-transform: capitalize;
}

div.status-chip.pending {
    background-color: orange;
    color: white;
}

div.status-chip.outstanding {
    background-color: cornflowerblue;
    color: white;
}

div.status-chip.paid {
    background-color: green;
    color: white;
}

div.status-chip.declined {
    background-color: crimson;
    color: white;
}

div.status-chip.overdue {
    background-color: crimson;
    color: white;
}

tr.bold-table-text td {
    font-weight: 600;
}
