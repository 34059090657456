.avatar {
    width: 96px;
    height: 96px;
}

.hotgiggity-logo-setlist {
    width: 96px;
    height: 96px;
    display: none;
}

.profile-name {
    padding-left: 10px;
    padding-top: 8px;
}

.toolbar-container {
    maring-bottom: 30px;
}

.grid-toolbar {
    padding-top: 13px;
    padding-left: 30px;
    display: "inline-flex";
}

.song-view-container {
    height: 100%;
    margin-top: 64px;
    height: calc(100% - 75px) !important;
    display: block;
    padding: 16px;
}

.print {
    display: none !important ;
}

@media print {
    @page {
        size: 210mm 297mm portrait;
        /* margin-left: -20px;
        margin-right: 50px;
        margin-top: -30px; */
    }

    body {
        -webkit-print-color-adjust: exact;
        width: 1310px !important;
    }

    td * {
        font-size: 8px;
    }

    .hotgiggity-logo-setlist {
        width: 127px;
        height: 35px;
        float: right !important;
        margin-left: 350px;
        display: block !important;
    }

    .no-print {
        display: none !important;
    }

    .print {
        display: flex !important;
    }

    .avatar-setlist {
        /* margin-left: 200px;
        display: flex; */
        display: block;
    }

    .avatar-text {
        vertical-align: middle;
        padding-top: 10px;
        padding-left: 3px;
    }

    .main-container {
        margin-left: 20px !important;
        margin-top: 10px !important;
    }

    .row-print {
        height: 35px !important;
    }

    header {
        display: none;
    }

    .th-songlist {
        background-color: #4d4d4d !important;
    }

    .head-title {
        color: aliceblue !important;
    }

    .printToolbar {
        width: 100%;
        padding-left: 20px !important;
    }

    .printToolbar img {
        display: block;
        float: right;
    }

    table {
        width: 100% !important;
    }

    .song-view-container {
        margin-top: 10px !important;
    }
}
