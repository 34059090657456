svg.profile-quote-icon {
    color: #fff;
    width: 60px;
    height: 60px;
}

div.profile-testimonial {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

div.profile-testimonial-container-even {
    background-color: #333;
    padding: 20px;
    height: 100%;
}

div.profile-testimonial-container-odd {
    background-color: #ff0c9c;
    padding: 20px;
    height: 100%;
}

p.profile-testimonial-content {
    color: #fff;
    font-family: "GellixThin";
}

p.profile-testimonial-from {
    color: #fff;
    font-family: "OpenSans";
}

div.profile-testimonial-card-content,
div.profile-testimonial-card-content:last-child {
    padding: 10px;
    height: 100%;
}

div.profile-testimonial-paper {
    width: 100%;
    height: 100%;
}
