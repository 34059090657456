.card-act-name {
    font-size: 16px !important;
    font-weight: bold !important;
}

.act-background-image {
    width: 100%;
    height: 202px;
    display: flex;
    background-size: cover;
    background-position: top;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    background-color: #222222;
    position: relative;
    object-position: center top;
}

.act-background-image .act-avatar {
    position: absolute;
    bottom: -26px;
    float: right;
    right: 18px;
    border: 3px solid #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    width: 60px;
    height: 60px;
    background-color: #efefef;
}

.manage-act .act-details {
    max-height: 200px;
    overflow: hidden;
}

.manage-act .act-bio {
    max-height: 123px;
    overflow: hidden;
}

.manage-act .act-action {
    margin: 0;
    padding: 8px 8px;
}

.manage-act .act-action > div {
    margin: 0;
}

.manage-act hr {
    background-color: rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
    .manage-act .act-details {
        height: 200px;
    }

    .manage-act .act-bio {
        height: 134px;
    }
}

@media (min-width: 1281px) {
    .act-background-image {
        height: 202px;
    }
}

@media (min-width: 2000px) {
    .act-background-image {
        height: 230px;
    }
}

.act-profile-link,
.act-profile-link:visited {
    color: black;
    text-decoration: none;
}

.act-profile-link:hover,
.act-profile-link:active {
    color: rgba(255, 12, 156,  0.8);
}

div.card-overlay {
    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.99) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.99) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.99) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    z-index: 100;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

div.card-overlay:hover {
    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.85) 0%,
        rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

div.card-overlay span {
    font-size: 10em;
    color: rgba(255, 12, 156,  0.8);
    margin-bottom: -34px;
    margin-top: -10px;
}

div.card-overlay:hover span {
    color: rgba(255, 12, 156,  1);
}

div.card-overlay p {
    color: rgba(255, 12, 156,  0.9);
}

div.card-overlay:hover p {
    color: rgba(255, 12, 156,  1);
}
