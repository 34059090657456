.dialog-action {
    display: unset !important;
    justify-content: inherit !important;
}

.style-venue {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    min-height: 150px;
  }
  
  /* On mouse-over, add a deeper shadow */
  .style-venue:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }


  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .container-vertical { 
    height: 100%;
    position: relative;
  }
  /* .MuiAvatar-img-57 {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: contain !important;
} */
  
  /* Add some padding inside the card container */
  /* .container {
    padding: 2px 16px;
  } */
