.gig-title-container {
    background-color: #333333 !important;
}

.gig-title {
    color: #ffffff !important;
    font-family: "GellixThin" !important;
}

.gig-title-badge {
    margin-right: 10px;
}

.gig-title-badge span {
    background-color: #e74c3c;
    color: #fff !important;
    font-size: 16px;
}

.gigs-container {
    padding-bottom: 0 !important;
}

.instagram-button-login {
    background: linear-gradient(rgb(101, 89, 202), rgb(188, 49, 143) 30%, rgb(227, 63, 95) 50%, rgb(247, 118, 56) 70%, rgb(254, 198, 109) 100%);
    color: #ffffff;
    border-radius: 3px;
    padding: 5px 16px;
    float: left;
    width: 100%;
    height:45px;
}



div.todays-gig-icon {
    text-align: center;
    font-size: 100px;
    color: #ff0c9c;
}

/* div.todays-gig-icon img {
    max-height: 300px;
    padding-right: 10px;
} */

div.todays-gig-caption-primary {
    text-align: center;
    font-size: 1.1rem;
    /* padding-left: 10px; */
}

div.todays-gig-caption-secondary {
    text-align: center;
}
