div.dashboard-detail-widget-main {
    height: 94px;
}

div.dashboard-detail-widget-main-container,
div.dashboard-detail-widget-detail-container {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

div.dashboard-detail-widget-main.primary {
    background-color: #ff0c9c;
}

div.dashboard-detail-widget-main.secondary {
    background-color: #333333;
}

div.dashboard-detail-widget-main .main-number {
    font-size: 30px;
    font-family: "Gellix";
    color: #333;
    margin: -8px 0px;
    text-align: center;
}

div.dashboard-detail-widget-main.secondary .main-number {
    color: #fff;
}

div.dashboard-detail-widget-main .main-caption {
    font-size: 16px;
    font-family: "Gellix";
    color: #fffa;
    text-align: center;
}

div.dashboard-detail-widget-detail {
    background-color: #fff;
    height: 94px;
}

div.dashboard-detail-widget-detail .detail-number {
    font-size: 22px;
    font-family: "Gellix";
    color: #ff0c9c;
    margin: -2px 0px;
    text-align: center;
    opacity: 0.8;
    font-weight: 400;
}

div.dashboard-detail-widget-detail .detail-number.red {
    color: #ff2222;
}

div.dashboard-detail-widget-detail .detail-caption {
    font-size: 14px;
    font-family: "Gellix";
    color: #333;
    text-align: center;
}
