.act-card-container {
    height: 500px;
}

.act-hero {
    width: 400px;
    height: 225px;
}

.expand {
    transform: rotate(0deg);
    transition: 10;
    margin-left: "auto";
}

.expandOpen {
    transform: "rotate(180deg)";
}

.actions {
    display: "flex";
}
