body {
    background-color: #3A3B3D !important;
}

.main-container {
    margin-top: 64px;
    margin-left: 230px;
    min-height: calc(100% - 64px) !important;
    display: block;
    padding: 16px;
}

.main-container-small {
    margin-top: 56px;
    min-height: calc(100% - 112px) !important;
    display: block;
    padding: 0px 0px 16px;
}

.container-wrapper {
    margin: 8px;
    width: calc(100% - 16px) !important;
}

.policy-container {
    margin-top: 64px;
    margin-left: 10vw;
    margin-right: 10vw;
    min-height: calc(100% - 64px) !important;
    display: block;
    padding: 5vw 5vw;
    text-align: justify;
}

.policy-container-mobile {
    min-height: calc(100% - 64px) !important;
    display: block;
    padding: 5vw 5vw;
    text-align: justify;
}

@media (min-width: 600px) {
    .main-container-small {
        margin-top: 128px;
        min-height: calc(100% - 128px) !important;
    }
}
