.dropzone {
    border: 3px dashed #ff0c9c;
    padding: 20px;
    height: 200px;
    width: 200px;
    align-items: center;
    align-content: center;
    display: flex;
    text-align: center;
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    ); */
}

.dropzone-gridlist {
    border: 3px dashed #ff0c9c;
    padding: 15px;
    height: 100%;
    width: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    text-align: center;
    /* background: repeating-linear-gradient(
        45deg,
        rgba(255, 12, 156, 0.2),
        rgba(255, 12, 156, 0.2) 20px,
        rgba(255, 12, 156, 0.3) 20px,
        rgba(255, 12, 156, 0.3) 40px
    ); */
}

.picture-container {
    position: relative;
    width: fit-content;
}

.picture-container .del-picture {
    position: absolute;
    bottom: 8px;
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    color: #fff;
    background-color: #ff0050;
}