.chat-message dl {
    display: flex;
    flex-flow: row wrap;
    border: solid #333;
    border-width: 1px 1px 0 0;
}
.chat-message dt {
    flex-basis: 20%;
    padding: 2px 4px;
    background: #333;
    text-align: right;
    color: #fff;
}
.chat-message dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
    border-bottom: 1px solid #333;
}

.chat-message hr {
    border-bottom: 1px solid #999;
}

.conversation-message {
    margin-top: 10px;
}

.participant-list{
   display: "flex";
   flex-wrap: "wrap";
}

.tag-item {
    background-color:rgb(255, 12, 156);
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    color: #fff;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
  }
  
  .tag-item > .button {
    background-color: rgba(0, 0, 0, 0.26);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    color: rgb(255, 12, 156);;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.chat .chat-avatar {
    float: left;
}
.chat.me .chat-avatar {
    float: right;
}

.chat-notif-message{
    text-align: center;
    font-weight: bolder;
    font-style: oblique;
}

.chat .chat-message {
    float: left;
    padding: 9px;
    border: 1px solid #eee;
    border-radius: 5px;
    background-color: #eee;
    margin-left: 15px;
    min-height: 30px;
    max-width: calc(100% - 110px);
    position: relative;
    margin-bottom: 10px;
}
.chat.me .chat-message {
    float: right;
    margin-right: 15px;
    margin-left: 0;
    margin-bottom: 10px;
}

.chat .chat-message:after {
    content: "";
    position: absolute;
    left: 0;
    top: 33px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #eee;
    border-left: 0;
    margin-top: -20px;
    margin-left: -9px;
}

.chat.me .chat-message:after {
    left: unset;
    right: 11px;
    border-right: 0;
    margin-right: -20px;
    border-left: 8px solid #eee;
    margin-left: 0;
}

.chat .chat-name {
    font-weight: 600;
    margin-bottom: 1px;
}
