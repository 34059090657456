.text-xs-left {
    text-align: left;
}

.text-xs-right {
    text-align: right;
}

.text-xs-center {
    text-align: center;
}

.text-xs-justify {
    text-align: justify;
}

form.form-relative {
    position: relative;
}

@media (min-width: 600px) {
    .text-sm-left {
        text-align: left;
    }
    .text-sm-right {
        text-align: right;
    }
    .text-sm-center {
        text-align: center;
    }
    .text-sm-justify {
        text-align: justify;
    }
}

@media (min-width: 960px) {
    .text-md-left {
        text-align: left;
    }
    .text-md-right {
        text-align: right;
    }
    .text-md-center {
        text-align: center;
    }
    .text-md-justify {
        text-align: justify;
    }
}

@media (min-width: 1200px) {
    .text-lg-left {
        text-align: left;
    }
    .text-lg-right {
        text-align: right;
    }
    .text-lg-center {
        text-align: center;
    }
    .text-lg-justify {
        text-align: justify;
    }
}

/* fix the bug for textfield select on material-ui rc 1 */

/*
div#menu- {
    visibility: visible;
}

div#header-popover,
div#notif-pop {
    visibility: visible;
} */

/* div.dialog-div {
    visibility: visible;
} */

/* .App main{
    background-color: #3A3B3D;
} */