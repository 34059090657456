.booking-calendar div.event,
.my-calendar div.event {
    padding: 5px 5px;
    border-radius: 5px;
    height: 100%;
}

.rbc-event {
    /* background-color: unset !important;
    border: unset !important; */
}

.rbc-time-view {
    background-color: white;
}

.rbc-agenda-view {
    background-color: white;
}

.rbc-event .rbc-event-label {
    color: #000000 !important;
    display: none;
}

.rbc-agenda-event-cell div {
    color: #ffffff !important;
}

.booking-calendar div.availability-event,
.my-calendar div.availability-event {
    background-color: #696969;
    font-size: 90%;
}

.booking-calendar div.google-calendar-event,
.my-calendar div.google-calendar-event {
    background-color: #696969;
    font-size: 90%;
    text-align: center;
}


.booking-calendar div.booking-New-event, div.booking-SentToAct-event,
.my-calendar div.booking-New-event {
    background-color: #1565c0;
    font-size: 90%;
}

.booking-calendar div.booking-Draft-event,
.my-calendar div.booking-Draft-event {
    background-color: #64b5f6;
    color: white;
    font-size: 90%;
}

.booking-calendar div.booking-Accepted-event,
.my-calendar div.booking-Accepted-event,
.booking-calendar div.booking-Ready-event,
.my-calendar div.booking-Ready-event,
.booking-calendar div.booking-LineupSet-event,
.my-calendar div.booking-LineupSet-event,
.booking-calendar div.booking-SetlistSet-event,
.my-calendar div.booking-SetlistSet-event,
.booking-calendar div.booking-PlayerConfirmedAndClientNotified-event,
.my-calendar div.booking-PlayerConfirmedAndClientNotified-event{
    background-color: #43a047;
    font-size: 90%;
}

.booking-calendar div.booking-Accepted-event,
.my-calendar div.booking-Accepted-event,
.booking-calendar div.booking-Ready-event,
.my-calendar div.booking-Ready-event,
.booking-calendar div.booking-LineupSet-event,
.my-calendar div.booking-LineupSet-event,
.booking-calendar div.booking-SetlistSet-event,
.my-calendar div.booking-SetlistSet-event,
.booking-calendar div.booking-PlayerConfirmedAndClientNotified-event,
.my-calendar div.booking-PlayerConfirmedAndClientNotified-event{
    background-color: #43a047;
    font-size: 90%;
}

.booking-calendar div.booking-PlayerNotified-event,
.my-calendar div.booking-PlayerNotified-event, .booking-calendar div.booking-PlayerDeclined-event,
.my-calendar div.booking-PlayerDeclined-event, .booking-calendar div.booking-AllPlayerConfirmed-event,
.my-calendar div.booking-AllPlayerConfirmed-event, .booking-calendar div.booking-ClientNotified-event,
.my-calendar div.booking-ClientNotified-event, .booking-calendar div.booking-PlayerInvitedAndClientNotified-event,
.my-calendar div.booking-PlayerInvitedAndClientNotified-event{
    background-color: #1b5e20;
    font-size: 90%;
}

.booking-calendar div.booking-Completed-event,
.my-calendar div.booking-Completed-event {
    background-color: #bdbdbd;
    font-size: 90%;
}

.booking-calendar div.booking-Declined-event,
.my-calendar div.booking-Declined-event {
    background-color: #b71c1c;
    font-size: 90%;
}

.booking-calendar div.booking-Hold-event,
.my-calendar div.booking-Hold-event {
    background-color: #ff0c9c;
    font-size: 90%;
}

.booking-calendar div.booking-Cancelled-event,
.my-calendar div.booking-Cancelled-event,
.booking-calendar div.booking-Cancelled-By-Act-event,
.my-calendar div.booking-Cancelled-By-Act-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-calendar div.booking-Cancelled-event,
.my-calendar div.booking-CancelledByAct-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-calendar div.booking-Cancelled-event,
.my-calendar div.booking-CancelledByActHistory-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-calendar div.booking-Resent-event,
.my-calendar div.booking-Resent-event {
    background-color: #b19cd9;
    font-size: 90%;
}

.booking-New-event, div.booking-SentToAct-event,
.booking-New-event {
    background-color: #1565c0;
    font-size: 90%;
}

.booking-Draft-event,
.booking-Draft-event {
    background-color: #64b5f6;
    color: white;
    font-size: 90%;
}

.booking-Accepted-event,
.booking-Accepted-event,
.booking-Ready-event,
.booking-Ready-event,
.booking-LineupSet-event,
.booking-LineupSet-event,
.booking-SetlistSet-event,
.booking-SetlistSet-event,
.booking-PlayerConfirmedAndClientNotified-event,
.booking-PlayerConfirmedAndClientNotified-event{
    background-color: #43a047;
    font-size: 90%;
}

.booking-Accepted-event,
.booking-Accepted-event,
.booking-Ready-event,
.booking-Ready-event,
.booking-LineupSet-event,
.booking-LineupSet-event,
.booking-SetlistSet-event,
.booking-SetlistSet-event,
.booking-PlayerConfirmedAndClientNotified-event,
.booking-PlayerConfirmedAndClientNotified-event{
    background-color: #43a047;
    font-size: 90%;
}

.booking-PlayerNotified-event,
.booking-PlayerNotified-event, .booking-PlayerDeclined-event,
.booking-PlayerDeclined-event, .booking-AllPlayerConfirmed-event,
.booking-AllPlayerConfirmed-event, .booking-ClientNotified-event,
.booking-ClientNotified-event, .booking-PlayerInvitedAndClientNotified-event,
.booking-PlayerInvitedAndClientNotified-event{
    background-color: #1b5e20;
    font-size: 90%;
}

.booking-Completed-event,
.booking-Completed-event {
    background-color: #bdbdbd;
    font-size: 90%;
}

.booking-Declined-event,
.booking-Declined-event {
    background-color: #b71c1c;
    font-size: 90%;
}

.booking-Hold-event,
.booking-Hold-event {
    background-color: #ff0c9c;
    font-size: 90%;
}

.booking-Cancelled-event,
.booking-Cancelled-event,
.booking-Cancelled-By-Act-event,
.booking-Cancelled-By-Act-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-Cancelled-event,
.booking-CancelledByAct-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-Cancelled-event,
.booking-CancelledByActHistory-event {
    background-color: #000000;
    font-size: 90%;
}

.booking-Resent-event,
.booking-Resent-event {
    background-color: #b19cd9;
    font-size: 90%;
}

.event-tooltip {
    z-index: 9999 !important;
}

.event-tooltip-open {
    font-size: 16px !important;
    line-height: 20px !important;
}

.rbc-row-content {
    z-index: inherit;
}

.agenda-event {
    cursor: pointer !important;
}
