.text-white {
    color: #ffffff !important;
}
.filterFull {
    width: 100%;
}
.filterHalf {
    width: 50%;
}
.dark-datepicker {
    max-width: 100%;
}
@media (min-width: 768px) {
    .filterFull,
    .filterHalf {
        width: unset;
    }
}
